import { call, put, takeEvery } from 'redux-saga/effects';
import { POST_CUSTOMER } from '../../constants';
import { PostCustomerService } from '../../services';
import { postCustomerSuccess, postCustomerError } from '../../redux/actions';

function* getAllTemplatesAsync({ payload }) {
  try {
    const { data } = yield call(PostCustomerService, payload);
    yield put(postCustomerSuccess(data));
  } catch {
    yield put(postCustomerError());
  }
}

export default function* rootSaga() {
  yield takeEvery(POST_CUSTOMER, getAllTemplatesAsync);
}
