import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Space, message, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeFileRevisionModal } from '../../redux/actions';
import { fileRevisionModes } from '../../constants';
import { putFileRevisionApproval, putFileRevisionApprovalCommittee, putFileRevisionRefuse, putFileRevisionRefuseCommittee } from '../../services';
import IntlMessages from '../../util/IntlMessages';

const { TextArea } = Input;

export function FileRevisionModal() {
  const dispatch = useDispatch();
  const [submiting, setSubmiting] = useState(false);
  const [obs, setObs] = useState('');
  const [form] = Form.useForm();
  const [title, setTitle] = useState('');
  const { fileRevisionModalIsOpen, fileRevision, fileRevisionMode } = useSelector(({ fileRevision }) => fileRevision);

  const closeModal = () => {
    setObs('');
    form.resetFields();
    dispatch(closeFileRevisionModal());
  };

  const getAction = () => {
    switch (fileRevisionMode) {
      case fileRevisionModes.APROVAL:
        return putFileRevisionApproval;
      case fileRevisionModes.REFUSE:
        return putFileRevisionRefuse;
      case fileRevisionModes.APROVAL_COMMITTEE:
        return putFileRevisionApprovalCommittee;
      case fileRevisionModes.REFUSE_COMMITTEE:
        return putFileRevisionRefuseCommittee;
      default:
        alert('Modo inválido.');
        throw new Error('Modo inválido.');
    }
  };

  const onFinish = async (params) => {
    try {
      setSubmiting(true);
      const action = getAction();
      await action(fileRevision._id, params);
      message.success('Solicitação processada com sucesso!');
      closeModal();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } finally {
      setSubmiting(false);
    }
  };

  const getPlaceholder = () => {
    if (!!fileRevisionMode) {
      switch (fileRevisionMode) {
        case fileRevisionModes.APROVAL:
          return 'Digite a observação que justifique a APROVAÇÃO deste documento.';
        case fileRevisionModes.REFUSE:
          return 'Digite a observação que justifique a REPROVAÇÃO deste documento.';
        case fileRevisionModes.APROVAL_COMMITTEE:
          return 'Digite a observação que justifique a APROVAÇÃO VIA COMITÊ deste documento.';
        case fileRevisionModes.REFUSE_COMMITTEE:
          return 'Digite a observação que justifique a REPROVAÇÃO VIA COMITÊ deste documento.';
        default:
          return 'Digite...';
      }
    }
    return 'Digite...';
  };

  useEffect(() => {
    if (!!fileRevisionMode) {
      switch (fileRevisionMode) {
        case fileRevisionModes.APROVAL:
          setTitle('Aprovar revisão');
          break;
        case fileRevisionModes.REFUSE:
          setTitle('Recusar revisão');
          break;
        case fileRevisionModes.APROVAL_COMMITTEE:
          setTitle('Aprovar revisão via comitê');
          break;
        case fileRevisionModes.REFUSE_COMMITTEE:
          setTitle('Recusar revisão via comitê');
          break;
        default:
          break;
      }
    }
  }, [fileRevisionMode]);

  return (
    <Modal
      title={title}
      visible={fileRevisionModalIsOpen}
      onCancel={closeModal}
      width={500}
      forceRender
      footer={
        <Space>
          <Button disabled={submiting} type="secondary" onClick={() => closeModal()}>
            <IntlMessages id="app.general.close_btn" />
          </Button>
          <Popconfirm disabled={submiting || obs.length === 0} title="Tem certeza?" okText="Sim" onConfirm={() => form.submit()}>
            <Button disabled={submiting || obs.length === 0} loading={submiting} type="primary">
              Enviar
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          revisionObservation: '',
        }}
        onFinish={onFinish}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: 'A observação é obrigatória.',
            },
          ]}
          label="Observação"
          name="revisionObservation"
        >
          <TextArea placeholder={getPlaceholder()} onChange={(e) => setObs(e.target.value)} rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
