import api from './ApiService';
import qs from 'query-string';

export async function getRoles(query) {
  const { data } = await api.get(`roles?${qs.stringify(query)}`);
  return data;
}

export async function getRole(id) {
  const { data } = await api.get(`roles/${id}`);
  return data;
}

export async function deleteRole(id) {
  await api.delete(`roles/${id}`);
  return true;
}

export async function postRole(params) {
  await api.post(`roles`, params);
  return true;
}

export async function putRole(id, params) {
  await api.put(`roles/${id}`, params);
  return true;
}

export async function getAllPermissions() {
  const { data } = await api.get('permissions-all');
  return data;
}

export async function getAllRoles() {
  const { data } = await api.get('roles-all');
  return data;
}
