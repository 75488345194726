import { SET_TREE_DATA, SET_TREE_LOADED, SET_TREE_EXPANDED, SET_TREE_CHECKED, SET_TREE_SELECTED } from '../../constants/ActionTypes';

const INIT_STATE = {
  treeData: [],
  loaded: [],
  expanded: [],
  checked: [],
  selected: [],
};

export default function Tree(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_TREE_DATA:
      return { ...state, treeData: action.payload };
    case SET_TREE_LOADED:
      return { ...state, loaded: action.payload };
    case SET_TREE_EXPANDED:
      return { ...state, expanded: action.payload };
    case SET_TREE_CHECKED:
      return { ...state, checked: action.payload };
    case SET_TREE_SELECTED:
      return { ...state, selected: action.payload };
    default:
      return state;
  }
}
