import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'antd';
import { userSignOut, toggleProfileModal, openGedPermissionsModal } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';

export default function UserProfile() {
  const dispatch = useDispatch();
  let { firstName, role } = useSelector(({ profile }) => profile);
  const { permissions = [] } = role;

  const canViewPermissions = permissions.some((x) => x.slug === 'ged-permissions.show');

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(toggleProfileModal())}>
        <IntlMessages id="app.user.my_account" />
      </li>
      {canViewPermissions && <li onClick={() => dispatch(openGedPermissionsModal())}>Permissões GED</li>}
      <li onClick={() => dispatch(userSignOut())}>
        <IntlMessages id="app.user.logout" />
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <span className="gx-avatar-name">
          {firstName}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
}
