import api from './ApiService';
import qs from 'query-string';

export async function getTemplates(query) {
  const { data } = await api.get(`templates?${qs.stringify(query)}`);
  return data;
}

export async function getAllTemplates() {
  const { data } = await api.get('templates-all');
  return data;
}

export async function getTemplate(id) {
  const { data } = await api.get(`templates/${id}`);
  return data;
}

export async function deleteTemplate(id) {
  await api.delete(`templates/${id}`);
  return true;
}

export async function postTemplate(params) {
  await api.post(`templates`, params);
  return true;
}

export async function putTemplate(id, params) {
  await api.put(`templates/${id}`, params);
  return true;
}

export async function getAllFolders() {
  const { data } = await api.get('folders-all');
  return data;
}
