import { UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE, TOGGLE_PROFILE_MODAL } from '../../constants/ActionTypes';

export const updateProfile = (params) => {
  return {
    type: UPDATE_PROFILE,
    payload: params,
  };
};

export const updateProfileSuccess = (profile) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: profile,
  };
};

export const toggleProfileModal = () => {
  return {
    type: TOGGLE_PROFILE_MODAL,
    payload: null,
  };
};
