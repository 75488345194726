import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, AutoComplete } from 'antd';
import { getSearch } from '../../services';
import { openFilePreviewDrawer } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';
import useDebounce from '../../util/UseDebounce';

const { Search } = Input;

export default function GlobalSearch() {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 500);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);

  const resetSearch = () => {
    setValue('');
    setResult([]);
  };

  useEffect(() => {
    const renderItem = (type, record) => {
      let path = [];
      const { title, _id } = record;
      if (type === 'file') {
        if (record.parent && record.parent.path) {
          path = record.parent.path;
        }
        return {
          value: _id,
          label: (
            <div onClick={() => onChoose(type, record)}>
              {title}
              <small>
                <p>{path.map((x) => x.title).join('/')}</p>
              </small>
            </div>
          ),
        };
      } else {
        if (record.path) {
          path = record.path;
        }
        return {
          value: _id,
          label: (
            <div onClick={() => onChoose(type, record)}>
              {title}
              <small>
                <p>{path.map((x) => x.title).join('/')}</p>
              </small>
            </div>
          ),
        };
      }
    };

    const onChoose = (type, record) => {
      resetSearch();
      if (type === 'file') {
        dispatch(openFilePreviewDrawer(record._id));
      } else {
        window.location.href = `/explorer?id=${record._id}`;
      }
    };

    const renderTitle = (title) => {
      return (
        <span>
          <strong>{title}</strong>
        </span>
      );
    };

    const getData = async () => {
      setLoading(true);
      try {
        const { data } = await getSearch(debouncedValue);
        setResult([
          {
            label: renderTitle(data.files.length > 0 ? <IntlMessages id="app.globalSearch.files" /> : <IntlMessages id="app.globalSearch.nofiles" />),
            options: data.files.length > 0 ? data.files.map((file) => renderItem('file', file)) : [],
          },
          {
            label: renderTitle(data.folders.length > 0 ? <IntlMessages id="app.globalSearch.folders" /> : <IntlMessages id="app.globalSearch.nofolders" />),
            options: data.folders.length > 0 ? data.folders.map((folder) => renderItem('folder', folder)) : [],
          },
        ]);
      } catch {}
      setLoading(false);
    };

    if (debouncedValue.length > 3) {
      getData();
    }
  }, [debouncedValue, dispatch]);

  return (
    <AutoComplete
      className="gx-mt-3"
      dropdownMatchSelectWidth={800}
      options={result}
      onChange={setValue}
      onSelect={() => setValue('')}
      value={value}
      style={{
        width: 450,
      }}
    >
      <Search loading={loading} placeholder="Buscar..." size="large" style={{ width: 400 }} />
    </AutoComplete>
  );
}
