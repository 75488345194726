import { call, put, select, takeLatest } from 'redux-saga/effects';
import { THEME_TYPE, NAV_STYLE, LAYOUT_TYPE, SWITCH_LANGUAGE } from '../../constants';
import { updateProfile } from '../../services';
import { updateProfileSuccess } from '../../redux/actions';

export const getSetting = (state) => {
  const { themeType, navStyle, layoutType } = state.settings;
  return { settings: { themeType, navStyle, layoutType } };
};

export const getSettingLocale = (state) => {
  const { locale } = state.settings.locale;
  return { locale };
};

export const getProfileId = (state) => {
  return state.profile?._id;
};

function* updateProfileSettingsAsync() {
  try {
    const params = yield select(getSetting);
    const id = yield select(getProfileId);
    const { data } = yield call(updateProfile, id, params);
    yield put(updateProfileSuccess(data));
  } catch {}
}

function* updateProfileLocaleAsync() {
  try {
    const params = yield select(getSettingLocale);
    const id = yield select(getProfileId);
    const { data } = yield call(updateProfile, id, params);
    yield put(updateProfileSuccess(data));
  } catch {}
}

export default function* rootSaga() {
  yield takeLatest([SWITCH_LANGUAGE], updateProfileLocaleAsync);
  yield takeLatest([THEME_TYPE, NAV_STYLE, LAYOUT_TYPE], updateProfileSettingsAsync);
}
