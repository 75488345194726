import { SWITCH_LANGUAGE, SWITCH_LANGUAGE_AFTER_LOGIN, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from '../../constants/ActionTypes';
import { LAYOUT_TYPE, LAYOUT_TYPE_AFTER_LOGIN, NAV_STYLE, NAV_STYLE_AFTER_LOGIN, THEME_TYPE, THEME_TYPE_AFTER_LOGIN } from '../../constants/ThemeSetting';

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return (dispatch) => {
    dispatch({ type: WINDOW_WIDTH, width });
  };
}

export function setThemeType(themeType) {
  return (dispatch) => {
    dispatch({ type: THEME_TYPE, themeType });
  };
}

export function setThemeTypeAfterLogin(themeType) {
  return (dispatch) => {
    dispatch({ type: THEME_TYPE_AFTER_LOGIN, themeType });
  };
}

export function onNavStyleChange(navStyle) {
  return (dispatch) => {
    dispatch({ type: NAV_STYLE, navStyle });
  };
}

export function onNavStyleChangeAfterLogin(navStyle) {
  return (dispatch) => {
    dispatch({ type: NAV_STYLE_AFTER_LOGIN, navStyle });
  };
}

export function onLayoutTypeChange(layoutType) {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE, layoutType });
  };
}

export function onLayoutTypeChangeAfterLogin(layoutType) {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE_AFTER_LOGIN, layoutType });
  };
}

export function switchLanguage(locale) {
  return (dispatch) => {
    dispatch({
      type: SWITCH_LANGUAGE,
      payload: locale,
    });
  };
}

export function switchLanguageAfterLogin(locale) {
  return (dispatch) => {
    dispatch({
      type: SWITCH_LANGUAGE_AFTER_LOGIN,
      payload: locale,
    });
  };
}
