export const getClientType = (clientType) => {
  switch (clientType) {
    case 1:
      return 'PR';
    case 2:
      return 'TRR';
    case 3:
      return 'Grande Cons.';
    case 4:
      return 'Cong.';
    default:
      return 'Não informado';
  }
};

export const getRealty = (realty) => {
  switch (realty) {
    case 1:
      return 'Próprio';
    case 2:
      return 'Alugado';
    default:
      return 'Não informado';
  }
};
export const getAttachedDocs = (doc) => {
  switch (doc) {
    case 1:
      return 'Contrato Social/Ata';
    case 2:
      return 'Última Alteração Contratual';
    case 3:
      return 'Cartão CNPJ';
    case 4:
      return 'Inscrição Estadual';
    case 5:
      return 'Últimos Balanços';
    case 6:
      return 'CPF e RG dos Sócios';
    case 7:
      return 'Procurações';
    default:
      return 'Outros';
  }
};
