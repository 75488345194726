import {
  OPEN_FILE_REVISION_MODAL,
  CLOSE_FILE_REVISION_MODAL,
  OPEN_NEW_FILE_REVISION_MODAL,
  CLOSE_NEW_FILE_REVISION_MODAL,
  GET_REVISORS,
  GET_REVISORS_SUCCESS,
  GET_REVISORS_ERROR,
} from '../../constants/ActionTypes';

export const openFileRevisionModal = (fileRevision, fileRevisionMode) => {
  return {
    type: OPEN_FILE_REVISION_MODAL,
    payload: { fileRevision, fileRevisionMode },
  };
};

export const closeFileRevisionModal = () => {
  return {
    type: CLOSE_FILE_REVISION_MODAL,
  };
};

export const openNewFileRevisionModal = (file) => {
  return {
    type: OPEN_NEW_FILE_REVISION_MODAL,
    payload: file,
  };
};

export const closeNewFileRevisionModal = () => {
  return {
    type: CLOSE_NEW_FILE_REVISION_MODAL,
  };
};

export const getRevisors = () => {
  return {
    type: GET_REVISORS,
  };
};

export const getRevisorsSuccess = (revisors) => {
  return {
    type: GET_REVISORS_SUCCESS,
    payload: revisors,
  };
};

export const getRevisorsError = () => {
  return {
    type: GET_REVISORS_ERROR,
  };
};
