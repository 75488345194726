import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_ALL_TEMPLATES, OPEN_FOLDER_MODAL } from '../../constants';
import { getAllTemplates } from '../../services';
import { getAllTemplatesSuccess, getAllTemplatesError } from '../../redux/actions';

function* getAllTemplatesAsync({ payload }) {
  try {
    const { data } = yield call(getAllTemplates, payload);
    yield put(getAllTemplatesSuccess(data));
  } catch {
    yield put(getAllTemplatesError());
  } finally {
  }
}

export default function* rootSaga() {
  yield takeLatest([GET_ALL_TEMPLATES, OPEN_FOLDER_MODAL], getAllTemplatesAsync);
}
