import React from 'react';
import { Font, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { getClientType, getRealty, getAttachedDocs } from './helpers';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Oswald',
    marginBottom: 20,
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 18,
    margin: 8,
    fontFamily: 'Oswald',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    fontSize: 12,
    margin: '8px 8px 0 8px',
    fontFamily: 'Times-Roman',
  },
  description: {
    margin: '6px 6px 12px 6px',
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
    padding: '7.9px 11px',
  },
  label: {
    margin: '15px',
    fontSize: 14,
    textAlign: 'center',
    textDecoration: 'underline',
    fontFamily: 'Oswald',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

export default function PDFDocument({ values }) {
  const {
    clients = [],
    clientType,
    realty,
    rentDeadline,
    onus,
    otherInfo = [],
    flag,
    galonage,
    affiliates,
    employees,
    openingHours,
    kms,
    leftGasoline,
    leftEthanol,
    leftDiesel,
    rigthGasoline,
    rigthEthanol,
    rigthDiesel,
    shareholders = [],
    patrimonies = [],
    bankReferences = [],
    commercials = [],
    attachedRegistered = [],
    signatureDate,
    marketAdvisorCredit,
    gn,
    initalCreditLimit,
    deadline,
    deadlineDate,
    creditCharge,
    clientSituation,
    experiences,
    othersBranchs,
    competitionSituation,
    economicSituation,
    advisorOpinion,
    creditProposal,
    creditProposalDeadline,
    advisorSignatureDate,
  } = values;

  return (
    <Document>
      <Page wrap size="A4" style={styles.body}>
        <Text style={styles.title}>Ficha Cadastral - RUFF</Text>
        <Text style={styles.author}>Data: {values.date}</Text>
        <Text style={styles.author}>Assessor de Mercado: {values.marketAdvisor}</Text>
        <Text style={styles.author}>Código assessor: {values.code}</Text>
        {clients.map((x, i) => (
          <View break={i > 0} key={i}>
            <Text style={styles.subtitle}>{x.companyName}</Text>

            <div style={styles.box}>
              <Text style={styles.text}>Razão social:</Text>
              <Text style={styles.description}>{x.companyName}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Nome fantasia:</Text>
              <Text style={styles.description}>{x.tradingName}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>CNPJ:</Text>
              <Text style={styles.description}>{x.CNPJ}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>IE:</Text>
              <Text style={styles.description}>{x.stateRegistration}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Registro na ANP:</Text>
              <Text style={styles.description}>{x.anpRegistration}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Data de Fundação:</Text>
              <Text style={styles.description}>{x.foundationDate}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Contato:</Text>
              <Text style={styles.description}>{x.contact}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Email (contato):</Text>
              <Text style={styles.description}>{x.email}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Telefone (contato)</Text>
              <Text style={styles.description}>{x.phone}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Endereço:</Text>
              <Text style={styles.description}>{x.address}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Bairro:</Text>
              <Text style={styles.description}>{x.district}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>CEP:</Text>
              <Text style={styles.description}>{x.zipcode}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Cidade:</Text>
              <Text style={styles.description}> {`${x.city} - ${x.state}`}</Text>
            </div>

            <Text style={styles.label}>Dados de cobrança:</Text>

            <div style={styles.box}>
              <Text style={styles.text}>Cidade | CEP:</Text>
              <Text style={styles.description}>
                {`${x.billingAddress}, ${x.billingDistrict} - ${x.billingCity} (${x.billingState})`} | {x.billingZipcode}
              </Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Caixa Postal:</Text>
              <Text style={styles.description}>{x.mailbox}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Email:</Text>
              <Text style={styles.description}>{x.billlingEmail}</Text>
            </div>

            <div style={styles.box}>
              <Text style={styles.text}>Telef. p/ contato:</Text>
              <Text style={styles.description}>{x.billingPhone}</Text>
            </div>
          </View>
        ))}

        <View break>
          <Text style={styles.subtitle}>Informações do Cliente</Text>

          <div style={styles.box}>
            <Text style={styles.text}>Atividade Econômica (Tipo de Cliente):</Text>
            <Text style={styles.description}>{getClientType(clientType)}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Imóvel | Prazo Aluguel | Ônus:</Text>
            <Text style={styles.description}>
              {getRealty(realty)} | {rentDeadline} | {onus}
            </Text>
          </div>

          {otherInfo.length > 0 && <Text style={styles.description}>Outras informações relevantes: {otherInfo.join(' | ')}</Text>}

          <div style={styles.box}>
            <Text style={styles.text}> Bandeira | Galonagem:</Text>
            <Text style={styles.description}>
              {flag} | {galonage}
            </Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Número de Filiais | Núm. Funcionários:</Text>
            <Text style={styles.description}>
              {affiliates} | {employees}
            </Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Hor. Funcionamento | Km Ida/Volta:</Text>
            <Text style={styles.description}>
              {openingHours} | {kms}
            </Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Número de Bombas (Gasolina):</Text>
            <Text style={styles.description}>{leftGasoline}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Número de Bombas (Etanol):</Text>
            <Text style={styles.description}>{leftEthanol}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Número de Bombas (Diesel):</Text>
            <Text style={styles.description}>{leftDiesel}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Número de Bicos (Gasolina):</Text>
            <Text style={styles.description}>{rigthGasoline}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Número de Bicos (Etanol):</Text>
            <Text style={styles.description}>{rigthEthanol}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Número de Bicos (Diesel):</Text>
            <Text style={styles.description}>{rigthDiesel}</Text>
          </div>
        </View>

        {shareholders.length > 0 && (
          <View break>
            <Text style={styles.subtitle}>Controle Acionário</Text>
            {shareholders.map((x, i) => (
              <View key={i}>
                <Text style={styles.label}>{x.shareholder}</Text>
                <Text style={styles.text}>Cargo: {x.role}</Text>
                <Text style={styles.text}>Porcentagem: {x.shareholding}</Text>
                <Text style={styles.text}>CPF: {x.cpf}</Text>
              </View>
            ))}
          </View>
        )}

        {patrimonies.length > 0 && (
          <View break>
            <Text style={styles.subtitle}>Patrimônio-Imóveis</Text>
            {patrimonies.map((x, i) => (
              <View key={i}>
                <Text style={styles.label}>{x.owner}</Text>
                <Text style={styles.text}>Tipo: {x.type}</Text>
                <Text style={styles.text}>Cidade/Estado: {x.cityState}</Text>
                <Text style={styles.text}>Valor: {x.value}</Text>
                <Text style={styles.text}>Ônus: {x.onus}</Text>
                <Text style={styles.text}>Avaliação: {x.evaluation}</Text>
              </View>
            ))}
          </View>
        )}

        {(bankReferences.length > 0 || commercials.length > 0) && (
          <View break>
            <Text style={styles.subtitle}>Referências Bancárias e Comerciais</Text>
            {bankReferences.map((x, i) => (
              <View key={i}>
                <Text style={styles.label}>Ref. bancária</Text>
                <Text style={styles.text}>Banco: {x.bank}</Text>
                <Text style={styles.text}>Agência: {x.agency}</Text>
                <Text style={styles.text}>Contato: {x.contact}</Text>
                <Text style={styles.text}>Telefone: {x.phone}</Text>
              </View>
            ))}
            {commercials.map((x, i) => (
              <View key={i}>
                <Text style={styles.label}>Ref. comercial</Text>
                <Text style={styles.text}>Nome: {x.commercial}</Text>
                <Text style={styles.text}>Freq. compra: {x.frequency}</Text>
                <Text style={styles.text}>Contato: {x.contact}</Text>
                <Text style={styles.text}>Telefone: {x.phone}</Text>
              </View>
            ))}
          </View>
        )}

        <View break>
          <Text style={styles.subtitle}>Documentos Anexados</Text>
          {attachedRegistered.length > 0 ? (
            attachedRegistered.map((x, i) => (
              <Text key={i} style={styles.text}>
                {getAttachedDocs(x)}
              </Text>
            ))
          ) : (
            <Text style={styles.description}>Nenhum</Text>
          )}
          <Text style={styles.subtitle}>Complementos</Text>

          <div style={styles.box}>
            <Text style={styles.text}>Dt. Assinatura Cliente:</Text>
            <Text style={styles.description}>{signatureDate}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Assessor de Mercado:</Text>
            <Text style={styles.description}>{marketAdvisorCredit}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>GN:</Text>
            <Text style={styles.description}>{gn}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Lim. Créd. Inicial:</Text>
            <Text style={styles.description}>{initalCreditLimit}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Prazo | Data:</Text>
            <Text style={styles.description}>
              {deadline} | {deadlineDate}
            </Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Créd. Cobrança:</Text>
            <Text style={styles.description}>{creditCharge}</Text>
          </div>
        </View>

        <View break>
          <Text style={styles.subtitle}>Parecer Comercial</Text>

          <Text style={styles.label}>Informações/Situações do cliente no mercado</Text>
          <Text style={styles.description}>{clientSituation}</Text>

          <Text style={styles.label}>Experiência dos Operadores</Text>
          <Text style={styles.description}>{experiences}</Text>

          <Text style={styles.label}>Opera outros postos? Bandeirados?</Text>
          <Text style={styles.description}>{othersBranchs}</Text>

          <Text style={styles.label}>Situação do posto frente à concorrência local</Text>
          <Text style={styles.description}>{competitionSituation}</Text>

          <Text style={styles.label}>Situação financeira dos sócios é estável? Possuem outras atividades?</Text>
          <Text style={styles.description}>{economicSituation}</Text>

          <Text style={styles.label}>Parecer comercial do Assessor</Text>
          <Text style={styles.description}>{advisorOpinion}</Text>
          {/* </View> */}

          {/* <View break> */}
          <Text style={styles.subtitle}>Limites</Text>

          <div style={styles.box}>
            <Text style={styles.text}>Limite de Crédito proposto:</Text>
            <Text style={styles.description}>{creditProposal}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Prazo:</Text>
            <Text style={styles.description}>{creditProposalDeadline}</Text>
          </div>

          <div style={styles.box}>
            <Text style={styles.text}>Lim. Créd. Inicial:</Text>
            <Text style={styles.description}>{advisorSignatureDate}</Text>
          </div>
        </View>
        {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed /> */}
      </Page>
    </Document>
  );
}
