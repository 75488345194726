import { SWITCH_LANGUAGE, SWITCH_LANGUAGE_AFTER_LOGIN, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from '../../constants/ActionTypes';
import { defaultLocale } from '../../config';
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_AFTER_LOGIN,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_AFTER_LOGIN,
  NAV_STYLE_FIXED,
  THEME_TYPE,
  THEME_TYPE_AFTER_LOGIN,
  THEME_TYPE_SEMI_DARK,
} from '../../constants/ThemeSetting';

const initialSettings = {
  navCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  pathname: '/',
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: defaultLocale,
};

export default function Settings(state = initialSettings, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case THEME_TYPE:
    case THEME_TYPE_AFTER_LOGIN:
      return {
        ...state,
        themeType: action.themeType,
      };
    case NAV_STYLE:
    case NAV_STYLE_AFTER_LOGIN:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case LAYOUT_TYPE:
    case LAYOUT_TYPE_AFTER_LOGIN:
      return {
        ...state,
        layoutType: action.layoutType,
      };
    case SWITCH_LANGUAGE:
    case SWITCH_LANGUAGE_AFTER_LOGIN:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
}
