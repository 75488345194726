import React, { useState } from 'react';
import { Button, Drawer, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import CustomScrollbars from '../util/CustomScrollbars';
import { onNavStyleChange, onLayoutTypeChange, setThemeType } from '../redux/actions';
import IntlMessages from '../util/IntlMessages';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  THEME_TYPE_DARK,
  THEME_TYPE_LITE,
  THEME_TYPE_SEMI_DARK,
} from '../constants/ThemeSetting';
import { framedImg, fullWidthImg, boxedImg, fixedImg, drawerNavImg, miniSidebarImg } from '../assets/images';

export default function CustomizerSystem() {
  const [isCustomizerOpened, setIsCustomizerOpened] = useState(false);
  const dispatch = useDispatch();

  const themeType = useSelector(({ settings }) => settings.themeType);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);

  const toggleCustomizer = () => {
    setIsCustomizerOpened(!isCustomizerOpened);
  };

  const onThemeTypeChange = (e) => {
    dispatch(setThemeType(e.target.value));
  };

  const onNavStyleChanges = (navStyle) => {
    dispatch(onNavStyleChange(navStyle));
  };

  const getCustomizerContent = () => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = '/css/dark_theme.css';
      link.className = 'style_dark_theme';
      document.body.appendChild(link);
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      const children = document.getElementsByClassName('style_dark_theme');
      if (children.length > 1) {
        for (let index = 0; index < children.length; index++) {
          if (index < children.length) {
            const child = children[index];
            child.parentNode.removeChild(child);
          }
        }
      }
    }

    return (
      <CustomScrollbars className="gx-customizer">
        <div className="gx-customizer-item">
          <h6 className="gx-mb-3 gx-text-uppercase">
            <IntlMessages id="app.theme.title" />
          </h6>
          <Radio.Group value={themeType} onChange={onThemeTypeChange}>
            <Radio.Button value={THEME_TYPE_LITE}>
              <IntlMessages id="app.theme.lite" />
            </Radio.Button>
            <Radio.Button value={THEME_TYPE_SEMI_DARK}>
              <IntlMessages id="app.theme.normal" />
            </Radio.Button>
            {/* <Radio.Button value={THEME_TYPE_DARK}>
              <IntlMessages id="app.theme.dark" />
            </Radio.Button> */}
          </Radio.Group>
        </div>

        <h6 className="gx-mb-3 gx-text-uppercase">
          <IntlMessages id="app.theme.style" />
        </h6>
        {getNavStyles(navStyle)}

        <h6 className="gx-mb-3 gx-text-uppercase">
          <IntlMessages id="app.theme.layout" />
        </h6>
        {getLayoutsTypes(layoutType)}
      </CustomScrollbars>
    );
  };

  const handleLayoutTypes = (layoutType) => {
    dispatch(onLayoutTypeChange(layoutType));
  };

  const getLayoutsTypes = (layoutType) => {
    return (
      <ul className="gx-layout-option gx-list-inline">
        <li>
          <span onClick={() => handleLayoutTypes(LAYOUT_TYPE_FRAMED)} className={`gx-pointer ${layoutType === LAYOUT_TYPE_FRAMED && 'active'}`}>
            <img src={framedImg} alt="framed" />
          </span>
        </li>
        <li>
          <span onClick={() => handleLayoutTypes(LAYOUT_TYPE_FULL)} className={`gx-pointer ${layoutType === LAYOUT_TYPE_FULL && 'active'}`}>
            <img src={fullWidthImg} alt="full width" />
          </span>
        </li>
        <li>
          <span onClick={() => handleLayoutTypes(LAYOUT_TYPE_BOXED)} className={`gx-pointer ${layoutType === LAYOUT_TYPE_BOXED && 'active'}`}>
            <img src={boxedImg} alt="boxed" />
          </span>
        </li>
      </ul>
    );
  };

  const getNavStyles = (navStyle) => {
    return (
      <ul className="gx-nav-option gx-list-inline">
        <li>
          <span onClick={() => onNavStyleChanges(NAV_STYLE_FIXED)} className={`gx-pointer ${navStyle === NAV_STYLE_FIXED && 'active'}`}>
            <img src={fixedImg} alt="fixed" />
          </span>
        </li>
        <li>
          <span onClick={() => onNavStyleChanges(NAV_STYLE_MINI_SIDEBAR)} className={`gx-pointer ${navStyle === NAV_STYLE_MINI_SIDEBAR && 'active'}`}>
            <img src={miniSidebarImg} alt="mini sidebar" />
          </span>
        </li>
        <li>
          <span onClick={() => onNavStyleChanges(NAV_STYLE_DRAWER)} className={`gx-pointer ${navStyle === NAV_STYLE_DRAWER && 'active'}`}>
            <img src={drawerNavImg} alt="drawer nav" />
          </span>
        </li>
      </ul>
    );
  };

  return (
    <>
      <Drawer placement="right" closable={false} onClose={toggleCustomizer} visible={isCustomizerOpened}>
        {getCustomizerContent()}
      </Drawer>
      <div className="gx-customizer-option">
        <Button type="primary" onClick={toggleCustomizer}>
          <i className="icon icon-setting fxicon-hc-spin gx-d-block" />
        </Button>
      </div>
    </>
  );
}
