import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userSignOut } from '../../redux/actions';
import CircularProgress from '../../components/CircularProgress/index';

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userSignOut());
  }, [dispatch]);

  return (
    <div className="gx-loader-view">
      <CircularProgress />
    </div>
  );
}
