import React from 'react';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toggleProfileModal, updateProfile } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';

export function ProfileModal() {
  const { modalIsOpen, firstName, lastName, email } = useSelector(({ profile }) => profile);
  const { loading } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const closeModal = () => {
    dispatch(toggleProfileModal());
    form.setFieldsValue({ firstName, lastName, email, password: '' }); //Recarrega campos
  };

  const validateFields = () => {
    form
      .validateFields()
      .then((values) => {
        const { firstName, lastName, password, email } = values;
        let params = { firstName, lastName, email };
        if (!!password) {
          params.password = password;
        }
        dispatch(updateProfile(params));
      })
      .catch((errorInfo) => {});
  };

  return (
    <Modal
      title={<IntlMessages id="app.user.my_account" />}
      okText={<IntlMessages id="app.general.save_btn" />}
      visible={modalIsOpen}
      onCancel={closeModal}
      footer={
        <>
          <Button type="secondary" onClick={closeModal}>
            <IntlMessages id="app.general.close_btn" />
          </Button>
          <Popconfirm placement="top" title={<IntlMessages id="app.general.are_you_sure" />} onConfirm={validateFields}>
            <Button key="submit" type="primary" loading={loading}>
              <IntlMessages id="app.general.update_btn_profile" />
            </Button>
          </Popconfirm>
        </>
      }
      onOk={validateFields}
    >
      <Form
        form={form}
        layout="vertical"
        name="profile_form"
        initialValues={{
          firstName,
          lastName,
          email,
          password: '',
        }}
      >
        <Form.Item
          name="firstName"
          label={<IntlMessages id="app.profile.firstName" />}
          rules={[
            {
              required: true,
              message: <IntlMessages id="app.validations.profile.firstName.required" />,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={<IntlMessages id="app.profile.lastName" />}
          rules={[
            {
              required: true,
              message: <IntlMessages id="app.validations.profile.lastName.required" />,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={<IntlMessages id="app.profile.email_label" />}
          rules={[
            {
              required: true,
              message: <IntlMessages id="app.validations.profile.email.required" />,
            },
            {
              type: 'email',
              message: <IntlMessages id="app.validations.profile.email.invalid" />,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={<IntlMessages id="app.profile.password" />} name="password">
          <Input.Password autoComplete="new-password" placeholder="Alterar senha" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
