const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    icon: 'es',
  },
  {
    languageId: 'portuguese',
    locale: 'pt-BR',
    name: 'Português',
    icon: 'br',
  },
];
export default languageData;
