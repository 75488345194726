import React, { useState } from 'react';
import { Modal, Form, Input, Button, Space, Select, Spin, message, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeNewFileRevisionModal, refreshGed } from '../../redux/actions';
import { postFileRevision } from '../../services';
import IntlMessages from '../../util/IntlMessages';

const { TextArea } = Input;
const { Option } = Select;

export function NewFileRevisionModal() {
  const [submiting, setSubmiting] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { newFileRevisionModalIsOpen, file, revisors, loadingRevisors } = useSelector(({ fileRevision }) => fileRevision);

  const closeModal = () => {
    form.resetFields();
    dispatch(closeNewFileRevisionModal());
  };

  const onFinish = async (data) => {
    try {
      setSubmiting(true);
      const params = { ...data, file: file._id };
      await postFileRevision(params);
      message.success('Solicitação enviada com sucesso!');
      dispatch(refreshGed());
      closeModal();
    } finally {
      setSubmiting(false);
    }
  };

  const loading = submiting || loadingRevisors;
  return (
    <Modal
      title="Nova solicitação de revisão"
      visible={newFileRevisionModalIsOpen}
      onCancel={closeModal}
      width={700}
      forceRender
      footer={
        <Space>
          <Button disabled={loading} type="secondary" onClick={() => closeModal()}>
            <IntlMessages id="app.general.close_btn" />
          </Button>
          <Popconfirm title="Tem certeza? Essa ação não poderá ser desfeita." onConfirm={() => form.submit()}>
            <Button disabled={loading} loading={submiting} type="primary">
              Enviar
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            revisors: [],
            observation: '',
          }}
          onFinish={onFinish}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ao menos um revisor é obrigatório.',
              },
            ]}
            name="revisors"
            label="Revisores"
          >
            <Select
              filterOption={(input, option) => !!option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              mode="multiple"
              placeholder="Selecione um ou mais..."
              disabled={loadingRevisors}
              autoFocus
              showArrow
              dropdownStyle={{ overflowY: 'auto' }}
            >
              {revisors.map((option) => (
                <Option key={option._id} value={option._id}>
                  {option.firstName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Observação"
            name="observation"
            extra={<p className="gx-mt-2 gx-mb-0">Atenção, caso haja uma solicitação de revisão em andamento, esta nova solicitação irá cancelar a vigente.</p>}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
