import React, { useState } from 'react';
import { Form, Button, Select, Spin, Space, message } from 'antd';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getAllUsers, getAllGroups, postGedPermission } from '../../services';

const { Option } = Select;

export default function GedPermissionsForm(props) {
  const { onCancel, refreshData } = props;
  const { folderId } = useSelector(({ gedPermissions }) => gedPermissions);
  const [submiting, setSubmiting] = useState(false);
  const [mode, setMode] = useState('group');
  const [searchingGroups, setSearchingGroups] = useState(false);
  const [groups, setGroups] = useState([]);
  const [searchingUsers, setSearchingUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();

  const onSearchUser = async (userName) => {
    try {
      if (!userName || userName === '') return setUsers([]);
      setSearchingUsers(true);
      const query = { name: userName };
      const { data } = await getAllUsers(query);
      setUsers(data);
    } finally {
      setSearchingUsers(false);
    }
  };
  const onSearchUserDebounced = debounce(onSearchUser, 500);

  const onSearchGroup = async (groupName) => {
    try {
      if (!groupName || groupName === '') return setUsers([]);
      setSearchingGroups(true);
      const query = { name: groupName };
      const { data } = await getAllGroups(query);
      setGroups(data);
    } finally {
      setSearchingGroups(false);
    }
  };
  const onSearchGroupDebounced = debounce(onSearchGroup, 500);

  const onFinish = async (data) => {
    try {
      let params = { folder: folderId };
      if (mode === 'user') {
        const { user } = data;
        params.user = user.value;
      } else {
        const { group } = data;
        params.group = group.value;
      }
      setSubmiting(true);
      await postGedPermission(params);
      setSubmiting(false);
      message.success('Permissão criada com sucesso!');
      refreshData();
      onCancel();
    } catch {
      setSubmiting(false);
    }
  };

  return (
    <Form onFinish={onFinish} form={form} layout="inline">
      <Form.Item colon={false} label="Usuário ou grupo?">
        <Select value={mode} onChange={(val) => setMode(val)}>
          <Option value="user">Usuário</Option>
          <Option value="group">Grupo</Option>
        </Select>
      </Form.Item>
      {mode === 'user' ? (
        <Form.Item rules={[{ required: true, message: 'Usuário é obrigatório.' }]} name="user" label="Usuário">
          <Select
            labelInValue
            autoFocus
            placeholder="Digite..."
            notFoundContent={searchingUsers ? <Spin size="small" /> : null}
            filterOption={false}
            showSearch
            onSearch={onSearchUserDebounced}
            style={{ width: 200 }}
          >
            {users.map((x) => (
              <Option key={x._id}>{x.firstName}</Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item rules={[{ required: true, message: 'Grupo é obrigatório.' }]} name="group" label="Grupo">
          <Select
            labelInValue
            autoFocus
            placeholder="Digite..."
            notFoundContent={searchingGroups ? <Spin size="small" /> : null}
            filterOption={false}
            showSearch
            onSearch={onSearchGroupDebounced}
            style={{ width: 200 }}
          >
            {groups.map((x) => (
              <Option key={x._id}>{x.name}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item>
        <Space>
          <Button type="secondary" disabled={submiting} onClick={onCancel}>
            Cancelar
          </Button>
          <Button loading={submiting} disabled={submiting} htmlType="submit" type="primary">
            Salvar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
