import {
  OPEN_FILE_REVISION_MODAL,
  CLOSE_FILE_REVISION_MODAL,
  OPEN_NEW_FILE_REVISION_MODAL,
  CLOSE_NEW_FILE_REVISION_MODAL,
  GET_REVISORS,
  GET_REVISORS_SUCCESS,
  GET_REVISORS_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  fileRevisionModalIsOpen: false,
  fileRevision: {},
  fileRevisionMode: '',
  newFileRevisionModalIsOpen: false,
  file: {},
  loadingRevisors: false,
  revisors: [],
};

export default function FileRevision(state = INIT_STATE, action) {
  switch (action.type) {
    case OPEN_FILE_REVISION_MODAL:
      return {
        ...state,
        fileRevisionModalIsOpen: true,
        fileRevision: action.payload.fileRevision,
        fileRevisionMode: action.payload.fileRevisionMode,
      };
    case CLOSE_FILE_REVISION_MODAL:
      return {
        ...state,
        fileRevisionModalIsOpen: false,
        fileRevision: {},
      };
    case OPEN_NEW_FILE_REVISION_MODAL:
      return {
        ...state,
        newFileRevisionModalIsOpen: true,
        file: action.payload,
      };
    case CLOSE_NEW_FILE_REVISION_MODAL:
      return {
        ...state,
        newFileRevisionModalIsOpen: false,
        file: {},
      };
    case GET_REVISORS:
      return { ...state, loadingRevisors: true };
    case GET_REVISORS_SUCCESS:
      return { ...state, loadingRevisors: false, revisors: action.payload };
    case GET_REVISORS_ERROR:
      return { ...state, loadingRevisors: false };
    default:
      return state;
  }
}
