import api from './ApiService';

export async function getComments(id = '') {
  const { data } = await api.get(`comments/${id}`);
  return data;
}

export async function postComment(fileId, message) {
  const { data } = await api.post(`comments/${fileId}`, { message });
  return data;
}

export async function putComment(fileId, messageId, message) {
  const { data } = await api.put(`comments/${fileId}/${messageId}`, { message });
  return data;
}

export async function deleteComment(fileId, messageId) {
  const { data } = await api.delete(`comments/${fileId}/${messageId}`);
  return data;
}
