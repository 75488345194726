import { OPEN_COPY_MOVE_MODAL, CLOSE_COPY_MOVE_MODAL } from '../../constants/ActionTypes';

export const openCopyMoveModal = (mode, objects = []) => {
  return {
    type: OPEN_COPY_MOVE_MODAL,
    payload: { mode, objects },
  };
};

export const closeCopyMoveModal = () => {
  return {
    type: CLOSE_COPY_MOVE_MODAL,
  };
};
