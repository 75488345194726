import React from 'react';
import moment from 'moment';
import { Button, Drawer, Table, Col, Row, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import bytes from 'bytes';
import { Link } from 'react-router-dom';
import { closeFilePreviewDrawer, openFileModal } from '../../../redux/actions';

export default function FilePreviewDrawer() {
  const dispatch = useDispatch();
  const { folderOpened, fileOpened, filePreviewDrawerOpened, loadingFile } = useSelector(({ ged }) => ged);
  const { permission = {} } = folderOpened;
  const { editFiles = false } = permission;

  const columns = [
    {
      title: 'Versões',
      render: (text, record, index) => parseInt(index + 1),
      children: [
        {
          title: 'Versão',
          dataIndex: 'versionNumber',
        },
        {
          title: 'Data',
          dataIndex: 'uploadedAt',
          render: (val) => moment(val).format('DD/MM/YYYY HH:mm'),
        },
        {
          title: 'Tamanho',
          dataIndex: 'size',
          render: (val) => bytes(val),
        },
      ],
    },
  ];

  const openEditFileModal = () => {
    dispatch(closeFilePreviewDrawer());
    dispatch(openFileModal(fileOpened));
  };

  return (
    <Drawer placement="right" title={fileOpened.title} onClose={() => dispatch(closeFilePreviewDrawer())} visible={filePreviewDrawerOpened}>
      <Spin spinning={loadingFile}>
        <Row style={{ marginTop: 30 }}>
          <Col span={24}>
            <Button onClick={() => dispatch(closeFilePreviewDrawer())}>
              <Link to={`/file/${fileOpened._id}`}>Mais detalhes</Link>
            </Button>
            {editFiles && (
              <Button type="primary" onClick={openEditFileModal}>
                Editar
              </Button>
            )}
          </Col>
          <Col span={24}>
            <p>Pasta: {fileOpened.parent?.title}</p>
          </Col>
          <Col span={24}>
            <p>Criado em: {moment(fileOpened.createdAt).format('DD/MM/YYYY HH:mm')} </p>
          </Col>
          <Col span={24}>
            <p>Última atualização: {moment(fileOpened.updatedAt).format('DD/MM/YYYY HH:mm')} </p>
          </Col>
        </Row>
        <Table className="gx-mt-3" pagination={false} bordered columns={columns} rowKey="versionNumber" dataSource={fileOpened.versions || []} size="small" />
      </Spin>
    </Drawer>
  );
}
