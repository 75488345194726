import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';

export default function NotFoundPage() {
  return (
    <Result
      status="warning"
      title={<IntlMessages id="app.general.page-not-found" />}
      extra={
        <Button type="primary" key="console">
          <Link to="/">
            <IntlMessages id="app.general.back-to-home" />
          </Link>
        </Button>
      }
    />
  );
}
