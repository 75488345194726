import api from './ApiService';
import qs from 'query-string';

export async function getTrash(query) {
  const { data } = await api.get(`trash-list?${qs.stringify(query)}`);
  return data;
}

export async function recoveryTrash(id) {
  const { data } = await api.post(`trash-recovery-item/${id}`);
  return data;
}

export async function deleteTrash(id) {
  await api.delete(`trash-delete-item/${id}`);
  return true;
}

export async function emptyTrash() {
  await api.post(`trash-empty`);
  return true;
}
