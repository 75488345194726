import { GET_ALL_CITIES, GET_ALL_CITIES_SUCCESS, GET_ALL_CITIES_ERROR } from '../../constants/ActionTypes';

export const getAllCities = (query) => {
  return {
    type: GET_ALL_CITIES,
    payload: query,
  };
};

export const getAllCitiesSuccess = (cities) => {
  return {
    type: GET_ALL_CITIES_SUCCESS,
    payload: cities,
  };
};

export const getAllCitiesError = () => {
  return {
    type: GET_ALL_CITIES_ERROR,
  };
};
