import React, { useEffect } from 'react';
import { message } from 'antd';
import CircularProgress from '../../components/CircularProgress/index';
import { translateNotification } from '../../util/IntlMessages';
import { confirmEmail, logout } from '../../services';
import { destaqueRuffImg } from '../../assets/images';

export default function EmailConfirmation(props) {
  const { confirmationEmailToken } = props.match.params;

  useEffect(() => {
    const confirmUserEmail = async () => {
      try {
        await confirmEmail(confirmationEmailToken);
        message.success(translateNotification('app.user.email_confirmed_action'));
      } finally {
        message.info(translateNotification('app.general.redirecting_to_login'));
        setTimeout(() => {
          return logout();
        }, 4000);
      }
    };
    confirmUserEmail();
  }, [confirmationEmailToken]);

  return (
    <div className="gx-app-login-wrap" style={{ backgroundColor: 'lightgrey' }}>
      <div style={{ backgroundImage: `url(${destaqueRuffImg})`, height: '50vh', textAlign: 'center' }}>
        <div className="gx-app-login-container" style={{ marginTop: 100 }}>
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        </div>
      </div>
    </div>
  );
}
