import { UPDATE_PROFILE_SUCCESS, TOGGLE_PROFILE_MODAL } from '../../constants/ActionTypes';

const INIT_STATE = {
  initURL: '/',
  modalIsOpen: false,
};

export default function Profile(state = INIT_STATE, action) {
  switch (action.type) {
    case UPDATE_PROFILE_SUCCESS:
      return action.payload;
    case TOGGLE_PROFILE_MODAL:
      return { ...state, modalIsOpen: !state.modalIsOpen };
    default:
      return state;
  }
}
