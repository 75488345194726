import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_TIMELINE } from '../../constants';
import { getTimeline } from '../../services';
import { getTimelineSuccess, getTimelineError } from '../../redux/actions';

function* getTimelineAsync({ payload }) {
  try {
    const { data } = yield call(getTimeline, payload);
    yield put(getTimelineSuccess(data));
  } catch {
    yield put(getTimelineError());
  } finally {
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_TIMELINE, getTimelineAsync);
}
