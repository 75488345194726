import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { loadState } from '../redux/store/localStorage';
import AppLocale from '../lngProvider';
import { defaultLocale } from '../config';

const InjectMassage = (props) => <FormattedMessage {...props} />;

export default injectIntl(InjectMassage, {
  withRef: false,
});

//Traduz fora do contexto/provider react render (redux)
export const translateNotification = (id) => {
  try {
    const { profile } = loadState();
    const { locale } = profile;
    return !!AppLocale[locale].messages[id] ? AppLocale[locale].messages[id] : id;
  } catch (error) {
    console.log(`LOG: translateNotification -> error`, error.message);
    return !!AppLocale[defaultLocale.locale].messages[id] ? AppLocale[defaultLocale.locale].messages[id] : id;
  }
};
