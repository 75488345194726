import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_COMMENTS } from '../../constants';
import { getComments } from '../../services';
import { getCommentsSuccess, getCommentsError } from '../../redux/actions';

function* getCommentsAsync({ payload }) {
  try {
    const { data } = yield call(getComments, payload);
    yield put(getCommentsSuccess(data));
  } catch {
    yield put(getCommentsError());
  } finally {
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_COMMENTS, getCommentsAsync);
}
