import { GET_COMMENTS, GET_COMMENTS_SUCCESS, GET_COMMENTS_ERROR } from '../../constants/ActionTypes';

const INIT_STATE = {
  comments: [],
  loadingComments: false,
};

export default function Comments(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_COMMENTS:
      return { ...state, loadingComments: true };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        loadingComments: false,
        comments: action.payload,
      };
    case GET_COMMENTS_ERROR:
      return { ...state, loadingComments: false };
    default:
      return state;
  }
}
