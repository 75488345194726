import { SHOW_SUCCESS, CLEAR_SUCCESS, SHOW_ERROR, CLEAR_ERROR, HIDE_LOADING, SHOW_LOADING } from '../../constants/ActionTypes';

export const showSuccess = (success) => {
  return {
    type: SHOW_SUCCESS,
    payload: success,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR_SUCCESS,
    payload: null,
  };
};

export const showError = (error) => {
  return {
    type: SHOW_ERROR,
    payload: error,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
    payload: null,
  };
};

export const showLoading = () => ({ type: SHOW_LOADING });

export const hideLoading = () => ({ type: HIDE_LOADING });
