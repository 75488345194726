import React, { useState } from 'react';
import { Input, Space, Button, Menu, Checkbox, Col, Row } from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import IntlMessages from '../../../util/IntlMessages';

export default function ColumnFiler(props) {
  const { onPressSearch, placeholder = 'Buscar...', listOption = false } = props;
  const [value, setValue] = useState('');

  const onReset = () => {
    setValue('');
    onPressSearch('');
  };

  if (listOption) {

    const menu = (
      <Menu
        onClick={() => onPressSearch(value)}
        items={[
          {
            label: '1st menu item',
            key: '1'
          },
          {
            label: '2nd menu item',
            key: '2',
          },
          {
            label: '3rd menu item',
            key: '3',
          },
        ]}
      />
    );


    return (
      <div style={{ padding: 8, width: '20%' }}>
        <Space>
          <Checkbox.Group style={{ width: '100%'}} onClick={() => onPressSearch(value)}>
            <Row>
              <Col span={4}>
                <Checkbox value="PENDING">Pendente de revisão</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="WAITING">Revisão solicitada (aguardando)</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="APPROVED">Aprovado</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="REFUSED">Recusado</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="PROCESSING">Em análise</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="REPLACED">Substituída por um nova solicitação de revisão/versão</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="COMMITTEE_WAITING">Aguardando análise de comitê (empate)</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="COMMITTEE_APPROVED">Aprovado pelo comitê</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox value="COMMITTEE_REFUSED">Reprovado pelo comitê</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Space>
        <Space>
          <Button onClick={onReset} size="small" style={{ width: 90 }}>
            <IntlMessages id="app.general.search-reset" />
          </Button>
        </Space>
      </div>
    );
  } else {
    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={placeholder}
          value={value}
          autoFocus
          onChange={(e) => setValue(e.target.value)}
          onKeyUp={(e) => (e.keyCode === 13 ? onPressSearch(value) : undefined)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => onPressSearch(value)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            {' '}
            <IntlMessages id="app.general.search" />
          </Button>
          <Button onClick={onReset} size="small" style={{ width: 90 }}>
            <IntlMessages id="app.general.search-reset" />
          </Button>
        </Space>
      </div>
    );
  }
}
