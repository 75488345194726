import React, { useEffect, useState } from 'react';
import { Typography, Form, Input, Col, Row, Select, Divider } from 'antd';
import MaskedInput from '../../../components/MaskedInput';

const { Title, Text } = Typography;
const { Option } = Select;

const estados = [
  { key: 'AC', value: 'Acre' },
  { key: 'AL', value: 'Alagoas' },
  { key: 'AP', value: 'Amapá' },
  { key: 'AM', value: 'Amazonas' },
  { key: 'BA', value: 'Bahia' },
  { key: 'CE', value: 'Ceará' },
  { key: 'DF', value: 'Distrito Federal' },
  { key: 'ES', value: 'Espírito Santo' },
  { key: 'GO', value: 'Goiás' },
  { key: 'MA', value: 'Maranhão' },
  { key: 'MT', value: 'Mato Grosso' },
  { key: 'MS', value: 'Mato Grosso do Sul' },
  { key: 'MG', value: 'Minas Gerais' },
  { key: 'PA', value: 'Pará' },
  { key: 'PB', value: 'Paraíba' },
  { key: 'PR', value: 'Paraná' },
  { key: 'PE', value: 'Pernambuco' },
  { key: 'PI', value: 'Piauí' },
  { key: 'RJ', value: 'Rio de Janeiro' },
  { key: 'RN', value: 'Rio Grande do Norte' },
  { key: 'RS', value: 'Rio Grande do Sul' },
  { key: 'RO', value: 'Rondônia' },
  { key: 'RR', value: 'Roraima' },
  { key: 'SC', value: 'Santa Catarina' },
  { key: 'SP', value: 'São Paulo' },
  { key: 'SE', value: 'Sergipe' },
  { key: 'TO', value: 'Tocantins' },
];

const labelText = (label) => <Text style={{ padding: '0 6px' }}>{label}</Text>;

export default function Client(props) {
  const [index, setIndex] = useState(props.index);
  const [field, setField] = useState(props.field);

  useEffect(() => {
    setIndex(props.index);
  }, [props.index]);
  useEffect(() => {
    setField(props.field);
  }, [props.field]);

  return (
    <>
      <Divider />
      <div style={{ marginTop: 20 }}>
        <Row>
          <Col span={24}>
            <Title level={4}>{`1.${+index + 1}`} - Dados do Cliente</Title>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <Form.Item
              name={[field.name, 'companyName']}
              fieldKey={[field.fieldKey, 'companyName']}
              label={labelText('Razão Social')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Razão Social" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={[field.name, 'tradingName']}
              fieldKey={[field.fieldKey, 'tradingName']}
              label={labelText('Nome Fantasia')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Nome Fantasia" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={6}>
            <Form.Item
              name={[field.name, 'CNPJ']}
              fieldKey={[field.fieldKey, 'CNPJ']}
              label={labelText('CNPJ')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <MaskedInput mask="99.999.999/9999-99" />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name={[field.name, 'stateRegistration']}
              fieldKey={[field.fieldKey, 'stateRegistration']}
              label={labelText('Inscrição Estadual')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Inscrição Estadual" />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name={[field.name, 'anpRegistration']}
              fieldKey={[field.fieldKey, 'anpRegistration']}
              label={labelText('Registro na ANP')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Registro na ANP" />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name={[field.name, 'foundationDate']}
              fieldKey={[field.fieldKey, 'foundationDate']}
              label={labelText('Data de Fundação')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <MaskedInput mask="99/99/9999" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'contact']}
              fieldKey={[field.fieldKey, 'contact']}
              label={labelText('Contato')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Contato" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'email']}
              fieldKey={[field.fieldKey, 'email']}
              label={labelText('Email')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'phone']}
              fieldKey={[field.fieldKey, 'phone']}
              label={labelText('Telefone')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Telefone" />
            </Form.Item>
          </Col>
          <Col xs={24} md={16}>
            <Form.Item
              name={[field.name, 'address']}
              fieldKey={[field.fieldKey, 'address']}
              label={labelText('Endereço')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Rua/Avenida número" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'district']}
              fieldKey={[field.fieldKey, 'district']}
              label={labelText('Bairro')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Bairro" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'zipcode']}
              fieldKey={[field.fieldKey, 'zipcode']}
              label={labelText('CEP')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <MaskedInput mask="99999-999" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'state']}
              fieldKey={[field.fieldKey, 'state']}
              label={labelText('Estado')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Select placeholder="UF">
                {estados.map((estado) => (
                  <Option key={estado.key + '_' + index} value={estado.key}>
                    {estado.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'city']}
              fieldKey={[field.fieldKey, 'city']}
              label={labelText('Cidade')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Cidade" />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Dados de cobrança</Divider>
        <Row>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'billingAddress']}
              fieldKey={[field.fieldKey, 'billingAddress']}
              label={labelText('Endereço cobrança')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Rua/Avenida número" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'billingDistrict']}
              fieldKey={[field.fieldKey, 'billingDistrict']}
              label={labelText('Bairro')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Bairro" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'billingZipcode']}
              fieldKey={[field.fieldKey, 'billingZipcode']}
              label={labelText('CEP')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <MaskedInput mask="99999-999" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'billingState']}
              fieldKey={[field.fieldKey, 'billingState']}
              label={labelText('Estado')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Select placeholder="Estado">
                {estados.map((estado) => {
                  return (
                    <Option key={estado.key + 'Billing_' + index} value={estado.key}>
                      {estado.value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'billingCity']}
              fieldKey={[field.fieldKey, 'billingCity']}
              label={labelText('Cidade')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Cidade" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'mailbox']}
              fieldKey={[field.fieldKey, 'mailbox']}
              label={labelText('Caixa Postal')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Caixa Postal" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'billlingEmail']}
              fieldKey={[field.fieldKey, 'billlingEmail']}
              label={labelText('Email')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={[field.name, 'billingPhone']}
              fieldKey={[field.fieldKey, 'billingPhone']}
              label={labelText('Telef. p/ contato')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input placeholder="Telef. p/ contato" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
