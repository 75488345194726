import { OPEN_GED_PERMISSIONS_MODAL, CLOSE_GED_PERMISSIONS_MODAL } from '../../constants/ActionTypes';

export const openGedPermissionsModal = (folderId) => {
  return {
    type: OPEN_GED_PERMISSIONS_MODAL,
    payload: folderId,
  };
};

export const closeGedPermissionsModal = () => {
  return {
    type: CLOSE_GED_PERMISSIONS_MODAL,
  };
};
