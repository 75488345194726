import React from 'react';
import { Layout } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import Sidebar from '../Sidebar/index';
import Topbar from '../Topbar/index';
import { footerText } from '../../config';
import App from '../../views';
import Customizer from '../Customizer';
import FilePreviewDrawer from '../../components/Drawers/FilePreviewDrawer';
import { ProfileModal, UploadVersionModal, FolderModal, GedPermissionsModal, FileModal, FileRevisionModal, NewFileRevisionModal, CopyMoveModal, FilePreviewModal } from '../Modals';

const { Content, Footer } = Layout;

export default function LoggedApp() {
  const match = useRouteMatch();

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <Topbar />
        <Content className="gx-layout-content">
          <FilePreviewDrawer />
          <App match={match} />
        </Content>
        <Footer>
          <Customizer />
          <ProfileModal />
          <UploadVersionModal />
          <FolderModal />
          <GedPermissionsModal />
          <FileModal />
          <FileRevisionModal />
          <NewFileRevisionModal />
          <CopyMoveModal />
          <FilePreviewModal />
          <div className="gx-layout-footer-content">{footerText}</div>
        </Footer>
      </Layout>
    </Layout>
  );
}
