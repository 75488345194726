import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Popconfirm, message, Row, Tooltip } from 'antd';
import { DeleteOutlined, SearchOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { closeGedPermissionsModal } from '../../redux/actions';
import { getGedPermissions, deleteGedPermission, patchGedPermission } from '../../services';
import IntlMessages from '../../util/IntlMessages';
import ColumnFilter from '../../components/Table/ColumnFilter';
import GedPermissionsForm from '../../components/GedPermissions/GedPermissionsForm';

const defaultPagination = { current: 1, pageSize: 20, total: 0 };
const defaultQuery = {
  current: 1,
  pageSize: 20,
  userName: '',
  groupName: '',
  folderName: '',
};

export function GedPermissionsModal() {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const { modalIsOpen, folderId } = useSelector(({ gedPermissions }) => gedPermissions);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [records, setRecords] = useState([]);
  const [query, setQuery] = useState(defaultQuery);

  const closeModal = () => {
    dispatch(closeGedPermissionsModal());
    setPagination(defaultPagination);
    setQuery(defaultQuery);
    setShowForm(false);
  };

  const refreshData = () => {
    setReloadData(!reloadData);
  };

  const deletePermission = async (id) => {
    try {
      setLoading(true);
      await deleteGedPermission(id);
      message.success('Permissão deletada com sucesso!');
      refreshData();
    } finally {
      setLoading(false);
    }
  };

  const updatePermission = async (id, key, value) => {
    try {
      setLoading(true);
      const params = { [key]: value };
      await patchGedPermission(id, params);
      refreshData();
    } finally {
      setLoading(false);
    }
  };

  const renderPermissionFlag = (record, key) => {
    if (record[key]) {
      return (
        <Tooltip title="Clique p/ desativar">
          <CheckCircleTwoTone onClick={() => updatePermission(record._id, key, false)} className="pointer" twoToneColor="#52c41a" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Clique p/ ativar">
          <CloseCircleTwoTone onClick={() => updatePermission(record._id, key, true)} className="pointer" twoToneColor="red" />
        </Tooltip>
      );
    }
  };

  const columns = [
    {
      title: 'Usuário',
      dataIndex: 'user',
      render: (val) => (val ? val.firstName : 'N/A'),
      filterIcon: <SearchOutlined style={{ color: query.userName !== '' ? '#1890ff' : undefined }} />,
      filterDropdown: <ColumnFilter placeholder="Buscar por usuário" onPressSearch={(userName) => setQuery({ ...query, userName })} />,
    },
    {
      title: 'Grupo',
      dataIndex: 'group',
      render: (val) => (val ? val.name : 'N/A'),
      filterIcon: <SearchOutlined style={{ color: query.groupName !== '' ? '#1890ff' : undefined }} />,
      filterDropdown: <ColumnFilter placeholder="Buscar por grupo" onPressSearch={(groupName) => setQuery({ ...query, groupName })} />,
    },
    {
      title: 'Pasta',
      dataIndex: 'folder',
      render: (val) => (val ? val.title : ''),
      filterIcon: <SearchOutlined style={{ color: query.folderName !== '' ? '#1890ff' : undefined }} />,
      filterDropdown: <ColumnFilter placeholder="Buscar por pasta" onPressSearch={(folderName) => setQuery({ ...query, folderName })} />,
    },
    {
      title: 'Somente leitura',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'readOnly'),
    },
    {
      title: 'Upload de arquivos',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'uploadFiles'),
    },
    {
      title: 'Deletar arquivos',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'deleteFiles'),
    },
    {
      title: 'Editar arquivos',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'editFiles'),
    },
    {
      title: 'Copiar/mover arquivos',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'copyMoveFiles'),
    },
    {
      title: 'Criar pastas',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'createFolders'),
    },
    {
      title: 'Editar pastas',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'editFolders'),
    },
    {
      title: 'Copiar/mover pastas',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'copyMoveFolders'),
    },
    {
      title: 'Deletar pastas',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'deleteFolders'),
    },
    {
      title: 'Editar permissões',
      align: 'center',
      render: (record) => renderPermissionFlag(record, 'editPermissions'),
    },
    {
      title: 'Atualizada em',
      dataIndex: 'updatedAt',
      render: (val) => moment(val).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: <IntlMessages id="app.general.actions" />,
      render: (record) => (
        <Row>
          <span className="gx-link">
            <Popconfirm title={<IntlMessages id="app.general.are_you_sure" />} onConfirm={() => deletePermission(record._id)}>
              <Tooltip title="Remover permissão">
                <DeleteOutlined className="gx-ml-4" />
              </Tooltip>
            </Popconfirm>
          </span>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        let _query = { ...query };
        if (!!folderId) {
          _query.folderId = folderId;
        }
        const { data } = await getGedPermissions(_query);
        const { current, pageSize, total } = data;
        setRecords(data.records);
        setPagination({ current, total, pageSize });
      } finally {
        setLoading(false);
      }
    };
    if (!!modalIsOpen) {
      getData();
    }
  }, [query, reloadData, folderId, modalIsOpen]);

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setQuery({ ...query, current, pageSize });
    setPagination(pagination);
  };

  return (
    <Modal
      title="Permissões"
      visible={modalIsOpen}
      onCancel={closeModal}
      forceRender
      width={1400}
      footer={
        <Button disabled={loading} loading={loading} type="secondary" onClick={closeModal}>
          <IntlMessages id="app.general.close_btn" />
        </Button>
      }
    >
      {showForm ? (
        <GedPermissionsForm refreshData={refreshData} onCancel={() => setShowForm(false)} />
      ) : (
        <>
          {!!folderId && (
            <Button onClick={() => setShowForm(true)} type="primary">
              Adicionar nova permissão nesta pasta
            </Button>
          )}
          <Table
            size="small"
            className="gx-table-responsive"
            columns={columns}
            rowKey={(record) => record._id}
            dataSource={records}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </>
      )}
    </Modal>
  );
}
