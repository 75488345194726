import api from './ApiService';
import qs from 'query-string';

export async function getGedPermissions(query = {}) {
  const { data } = await api.get(`ged-permissions?${qs.stringify(query)}`);
  return data;
}

export async function postGedPermission(params) {
  await api.post('ged-permissions', params);
}

export async function patchGedPermission(id, params) {
  await api.patch(`ged-permissions/${id}`, params);
}

export async function deleteGedPermission(id) {
  await api.delete(`ged-permissions/${id}`);
}
