import React from 'react';
import {
  HomeOutlined,
  TeamOutlined,
  SafetyOutlined,
  FolderOpenOutlined,
  PartitionOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  UnlockOutlined,
  FileDoneOutlined,
  CopyOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

const style = { fontSize: '18px' };

//Icons: https://ant.design/components/icon/
const routes = [
  {
    group_key: 'main',
    language_key: 'menu.main',
    menus: [
      {
        menu_key: 'dashboard',
        path: '/dashboard',
        language_key: 'menu.dashboard',
        icon: <HomeOutlined style={style} />,
        childs: [],
      },
      {
        menu_key: 'explorer',
        path: '/explorer',
        language_key: 'menu.explorer',
        icon: <FolderOpenOutlined style={style} />,
        childs: [],
      },
      {
        menu_key: 'tree',
        path: '/tree',
        language_key: 'menu.tree',
        icon: <PartitionOutlined style={style} />,
        childs: [],
      },
      {
        menu_key: 'groups',
        path: '/groups',
        language_key: 'menu.groups',
        icon: <UnlockOutlined style={style} />,
        permission: 'groups.show',
        childs: [],
      },
      {
        menu_key: 'templates',
        path: '/templates',
        language_key: 'menu.templates',
        icon: <CopyOutlined style={style} />,
        permission: 'templates.show',
        childs: [],
      },
      {
        menu_key: 'file-revisions',
        path: '/file-revisions',
        language_key: 'menu.file-revisions',
        icon: <FileDoneOutlined style={style} />,
        // permission: 'file-revisions.full',
        childs: [],
      },
      {
        menu_key: 'users',
        path: '/users',
        language_key: 'menu.users',
        icon: <TeamOutlined style={style} />,
        permission: 'users.show',
        childs: [],
      },
      {
        menu_key: 'roles',
        path: '/roles',
        language_key: 'menu.roles',
        icon: <SafetyOutlined style={style} />,
        permission: 'roles.show',
        childs: [],
      },
      {
        menu_key: 'trash',
        path: '/trash',
        language_key: 'menu.trash',
        icon: <DeleteOutlined style={style} />,
        permission: 'trash.access',
        childs: [],
      },
      {
        menu_key: 'tickets',
        path: '/tickets',
        language_key: 'menu.tickets',
        icon: <QuestionCircleOutlined style={style} />,
        childs: [],
      },
      {
        menu_key: 'logout',
        path: '/logout',
        language_key: 'menu.logout',
        icon: <LogoutOutlined style={style} />,
        childs: [],
      },
    ],
  },
];

export default routes;
