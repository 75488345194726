import api from './ApiService';

export async function getRoot() {
  const { data } = await api.get('ged-root');
  return data;
}

export async function getFolder(folderId) {
  const { data } = await api.get(`ged/${folderId}`);
  return data;
}

export async function deleteFolder(folderId) {
  await api.delete(`folders/${folderId}`);
}

export async function getFilesQty(folderId) {
  const { data } = await api.get(`folders-files-qty/${folderId}`);
  return data;
}

export async function deleteFile(fileId) {
  await api.delete(`files/${fileId}`);
}

export async function postDeleteFiles(params) {
  await api.post('files-delete', params);
}

export async function postFolder(params) {
  await api.post('folders', params);
}

export async function putFolder(id, params) {
  await api.put(`folders/${id}`, params);
}

export async function getFile(id) {
  const { data } = await api.get(`files/${id}`);
  return data;
}

export async function putFile(id, params) {
  await api.put(`files/${id}`, params);
}

export async function postMoveFolders(params) {
  await api.post('folders-move', params);
}

export async function postCopyFolders(params) {
  await api.post('folders-copy', params);
}

export async function postMoveFiles(params) {
  await api.post('files-move', params);
}

export async function postCopyFiles(params) {
  await api.post('files-copy', params);
}

export async function getDownloadUrlFile(id) {
  const { data } = await api.get(`files-download-url/${id}`);
  return data;
}

export function downloadFolder(id) {
  return api.get(`download-folder/${id}`, { responseType: 'blob' });
}
