import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import store, { history } from './redux/store';
import App from './containers/App';
import './assets/vendors/style';
import './assets/css/custom.css';

const RootApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </>
    </ConnectedRouter>
  </Provider>
);

export default RootApp;
