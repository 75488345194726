import { GET_TIMELINE, GET_TIMELINE_SUCCESS, GET_TIMELINE_ERROR } from '../../constants/ActionTypes';

export const getTimeline = (id = '') => {
  return {
    type: GET_TIMELINE,
    payload: id,
  };
};

export const getTimelineSuccess = (timeline) => {
  return {
    type: GET_TIMELINE_SUCCESS,
    payload: timeline,
  };
};

export const getTimelineError = () => {
  return {
    type: GET_TIMELINE_ERROR,
  };
};
