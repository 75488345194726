import { SHOW_SUCCESS, CLEAR_SUCCESS, SHOW_ERROR, CLEAR_ERROR, SHOW_LOADING, HIDE_LOADING } from '../../constants/ActionTypes';

const INIT_STATE = {
  success: '',
  error: '',
  loading: false,
};

export default function Common(state = INIT_STATE, action) {
  switch (action.type) {
    case SHOW_SUCCESS:
      return { ...state, success: action.payload };
    case CLEAR_SUCCESS:
      return { ...state, success: '' };
    case SHOW_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_ERROR:
      return { ...state, error: '' };
    case SHOW_LOADING:
      return { ...state, loading: true };
    case HIDE_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}
