import { GET_ALL_CITIES, GET_ALL_CITIES_SUCCESS, GET_ALL_CITIES_ERROR } from '../../constants/ActionTypes';

const INIT_STATE = {
  cities: [],
  loadingCities: false,
};

export default function City(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_ALL_CITIES:
      return { ...state, loadingCities: true };
    case GET_ALL_CITIES_SUCCESS:
      return { ...state, cities: action.payload, loadingCities: false };
    case GET_ALL_CITIES_ERROR:
      return { ...state, loadingCities: false };
    default:
      return state;
  }
}
