import { GET_COMMENTS, GET_COMMENTS_SUCCESS, GET_COMMENTS_ERROR } from '../../constants/ActionTypes';

export const getComments = (id = '') => {
  return {
    type: GET_COMMENTS,
    payload: id,
  };
};

export const getCommentsSuccess = (comments) => {
  return {
    type: GET_COMMENTS_SUCCESS,
    payload: comments,
  };
};

export const getCommentsError = () => {
  return {
    type: GET_COMMENTS_ERROR,
  };
};
