import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_ALL_CITIES } from '../../constants';
import { getAllCities } from '../../services';
import { getAllCitiesSuccess, getAllCitiesError } from '../../redux/actions';

function* getAllCitiesAsync({ payload }) {
  try {
    const { data } = yield call(getAllCities, payload);
    yield put(getAllCitiesSuccess(data));
  } catch {
    yield put(getAllCitiesError());
  } finally {
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_ALL_CITIES, getAllCitiesAsync);
}
