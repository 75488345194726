import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootSaga from '../sagas/index';
import createRootReducer from '../reducers';
import { logger } from 'redux-logger';
import { saveState, loadState } from './localStorage';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];
if (process.env.NODE_ENV === 'development') middlewares.push(logger);

const preloadedState = loadState(); //Load from local storage
const store = createStore(
  createRootReducer(history), // root reducer with router state
  preloadedState,
  compose(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      ...middlewares
    )
  )
);
store.subscribe(() => {
  const { auth, settings, profile } = store.getState(); //Persiste no localStorage
  return saveState({ auth, settings, profile });
});
sagaMiddleware.run(rootSaga);

export default store;
