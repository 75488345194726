import { GET_TIMELINE, GET_TIMELINE_SUCCESS, GET_TIMELINE_ERROR } from '../../constants/ActionTypes';

const INIT_STATE = {
  loadingTimeline: false,
  timeline: [],
};

export default function Timeline(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_TIMELINE:
      return { ...state, loadingTimeline: true };
    case GET_TIMELINE_SUCCESS:
      return {
        ...state,
        loadingTimeline: false,
        timeline: action.payload,
      };
    case GET_TIMELINE_ERROR:
      return { ...state, loadingTimeline: false };
    default:
      return state;
  }
}
