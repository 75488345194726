import api from './ApiService';
import qs from 'query-string';

export async function getUsers(query) {
  const { data } = await api.get(`users?${qs.stringify(query)}`);
  return data;
}

export async function getAllUsers(query) {
  const { data } = await api.get(`users-all?${qs.stringify(query)}`);
  return data;
}

export async function getUser(id) {
  const { data } = await api.get(`users/${id}`);
  return data;
}

export async function deleteUser(id) {
  await api.delete(`users/${id}`);
  return true;
}

export async function postUser(params) {
  await api.post(`users`, params);
  return true;
}

export async function putUser(id, params) {
  await api.put(`users/${id}`, params);
  return true;
}
