import { call, put, takeLatest, select } from 'redux-saga/effects';
import { GET_ROOT, GET_FOLDER, REFRESH_GED, GET_FILE, OPEN_FILE_PREVIEW_DRAWER, CREATE_FOLDER, UPDATE_FOLDER, UPDATE_FILE, DELETE_FOLDER, DELETE_FILE, DELETE_FILES } from '../../constants';
import { getRoot, getFolder, postFolder, putFolder, getFile, putFile, deleteFolder, deleteFile, postDeleteFiles } from '../../services';
import {
  getRootSuccess,
  getRootError,
  getFolderSuccess,
  getFolderError,
  refreshGed,
  getFileSuccess,
  getFileError,
  createFolderSuccess,
  createFolderError,
  updateFolderSuccess,
  updateFolderError,
  updateFileSuccess,
  updateFileError,
  closeFolderModal,
  closeFileModal,
  getRoot as getRootAction,
  getFolder as getFolderAction,
  getFile as getFileAction,
  deleteFolderSuccess,
  deleteFolderError,
  deleteFileSuccess,
  deleteFileError,
} from '../../redux/actions';

function* getRootAsync() {
  try {
    const { data } = yield call(getRoot);
    yield put(getRootSuccess(data));
  } catch {
    yield put(getRootError());
  } finally {
  }
}

function* getFolderAsync({ payload }) {
  try {
    const { data } = yield call(getFolder, payload);
    yield put(getFolderSuccess(data));
  } catch {
    yield put(getFolderError());
  } finally {
  }
}

function* getFileAsync({ payload }) {
  try {
    const { data } = yield call(getFile, payload);
    yield put(getFileSuccess(data));
  } catch {
    yield put(getFileError());
  } finally {
  }
}

function* createFolderAsync({ payload }) {
  try {
    yield call(postFolder, payload);
    yield put(createFolderSuccess());
    yield put(closeFolderModal());
    yield put(refreshGed());
  } catch {
    yield put(createFolderError());
  } finally {
  }
}

function* updateFolderAsync({ payload }) {
  try {
    const { id, params } = payload;
    yield call(putFolder, id, params);
    yield put(updateFolderSuccess());
    yield put(closeFolderModal());
    yield put(refreshGed());
  } catch {
    yield put(updateFolderError());
  } finally {
  }
}

function* refreshGedAsync() {
  const getFolderIdOpened = (state) => {
    return state.ged.folderOpened?._id;
  };
  const getFileIdOpened = (state) => {
    return state.ged.fileOpened?._id;
  };

  const folderIdOpened = yield select(getFolderIdOpened);
  const fileIdOpened = yield select(getFileIdOpened);

  if (!!folderIdOpened) {
    yield put(getFolderAction(folderIdOpened));
  } else {
    yield put(getRootAction());
  }
  if (!!fileIdOpened) {
    yield put(getFileAction(fileIdOpened));
  }
}

function* updateFileAsync({ payload }) {
  try {
    const { id, params } = payload;
    yield call(putFile, id, params);
    yield put(updateFileSuccess());
    yield put(closeFileModal());
    yield put(refreshGed());
  } catch {
    yield put(updateFileError());
  } finally {
  }
}

function* deleteFolderAsync({ payload }) {
  try {
    yield call(deleteFolder, payload);
    yield put(deleteFolderSuccess());
    yield put(refreshGed());
  } catch {
    yield put(deleteFolderError());
  } finally {
  }
}

function* deleteFileAsync({ payload }) {
  try {
    yield call(deleteFile, payload);
    yield put(deleteFileSuccess());
    yield put(refreshGed());
  } catch {
    yield put(deleteFileError());
  } finally {
  }
}

function* deleteFilesAsync({ payload }) {
  try {
    yield call(postDeleteFiles, payload);
    yield put(deleteFileSuccess());
    yield put(refreshGed());
  } catch {
    yield put(deleteFileError());
  } finally {
  }
}

export default function* rootSaga() {
  yield takeLatest([GET_FILE, OPEN_FILE_PREVIEW_DRAWER], getFileAsync);
  yield takeLatest(GET_ROOT, getRootAsync);
  yield takeLatest(GET_FOLDER, getFolderAsync);
  yield takeLatest(REFRESH_GED, refreshGedAsync);
  yield takeLatest(CREATE_FOLDER, createFolderAsync);
  yield takeLatest(UPDATE_FOLDER, updateFolderAsync);
  yield takeLatest(UPDATE_FILE, updateFileAsync);
  yield takeLatest(DELETE_FOLDER, deleteFolderAsync);
  yield takeLatest(DELETE_FILE, deleteFileAsync);
  yield takeLatest(DELETE_FILES, deleteFilesAsync);
}
