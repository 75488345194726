import React from 'react';
import { Typography, Form, Input, Col, Row, Divider, Button } from 'antd';

const { Title } = Typography;
const fundoCinza = 'rgba(0, 0, 0, 0.05)';
const fundoBranco = '#ffffff';

export default function Patrimony() {
  return (
    <>
      <Divider />
      <div style={{ marginTop: 20 }}>
        <Row>
          <Col span={24}>
            <Title level={4}>4 - Patrimônio-Imóveis</Title>
          </Col>
        </Row>

        <Form.List name="patrimonies">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div
                  key={field.key}
                  style={{
                    backgroundColor: index % 2 === 0 ? fundoCinza : fundoBranco,
                    margin: '10px 0',
                    padding: '5px 0',
                  }}
                >
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Item name={[field.name, 'owner']} fieldKey={[field.fieldKey, 'owner']}>
                        <Input placeholder="NOME DO PROPRIETÁRIO" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={2}>
                      <Form.Item name={[field.name, 'type']} fieldKey={[field.fieldKey, 'type']}>
                        <Input placeholder="TIPO" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={4}>
                      <Form.Item name={[field.name, 'cityState']} fieldKey={[field.fieldKey, 'cityState']}>
                        <Input placeholder="CIDADE/ESTADO" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Item name={[field.name, 'value']} fieldKey={[field.fieldKey, 'value']}>
                        <Input placeholder="VALOR" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Item name={[field.name, 'onus']} fieldKey={[field.fieldKey, 'onus']}>
                        <Input placeholder="ÔNUS" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={3}>
                      <Form.Item name={[field.name, 'evaluation']} fieldKey={[field.fieldKey, 'evaluation']}>
                        <Input placeholder="AVALIAÇÃO" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={3}>
                      <Button type="link" key={'removeShareholderButton_' + index} onClick={() => remove(index)} style={{ marginBottom: 0 }}>
                        Remover
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}

              <Row className="gx-mr-4 gx-mt-2" justify="end">
                <Col>
                  <Form.Item>
                    <Button onClick={() => add()}>Adicionar pat.</Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
}
