export * from './ActionTypes';
export * from './ThemeSetting';

export const statesArray = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

export const fileStatusColors = {
  PENDING: '#048FDE',
  WAITING: '#FFCB26',
  APPROVED: '#52c41a',
  REFUSED: '#E6121D',
  PROCESSING: '#FFCB26',
  REPLACED: '#E6121D',
  COMMITTEE_WAITING: '#FFCB26',
  COMMITTEE_APPROVED: '#52c41a',
  COMMITTEE_REFUSED: '#E6121D',
};

export const fileStatusName = {
  PENDING: 'Pendente de revisão',
  WAITING: 'Revisão solicitada (aguardando)',
  APPROVED: 'Aprovado',
  REFUSED: 'Recusado',
  PROCESSING: 'Em análise',
  REPLACED: 'Substituída por um nova solicitação de revisão/versão',
  COMMITTEE_WAITING: 'Aguardando análise de comitê (empate)',
  COMMITTEE_APPROVED: 'Aprovado pelo comitê',
  COMMITTEE_REFUSED: 'Reprovado pelo comitê',
};

export const fileRevisionModes = {
  APROVAL: 'APROVAL',
  REFUSE: 'REFUSE',
  APROVAL_COMMITTEE: 'APROVAL_COMMITTEE',
  REFUSE_COMMITTEE: 'REFUSE_COMMITTEE',
};

export const defaultDashboardResume = {
  revisions: {
    pending: 0,
    processing: 0,
    pendingUser: 0,
    processingUser: 0,
  },
  tickets: {
    pending: 0,
    active: 0,
    closed: 0,
    pendingUser: 0,
    activeUser: 0,
    closedUser: 0,
  },
  files: {
    all: 0,
  },
  folders: {
    all: 0,
  },
};

const docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const doc = 'application/msword';
const pdf = 'application/pdf';
const images = 'image/png,image/jpeg,image/jpg';
const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const xls = 'application/vnd.ms-excel,application/msexcel,application/x-msexcel,application/x-ms-excel,application/x-excel,application/x-dos_ms_excel,application/xls,application/x-xls';
export const authorizedMimeTypesUpload = `${pdf},${images},${doc},${docx},${xls},${xlsx}`;
