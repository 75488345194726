import api from './ApiService';
import qs from 'query-string';

export async function getGroups(query) {
  const { data } = await api.get(`groups?${qs.stringify(query)}`);
  return data;
}

export async function getAllGroups(query) {
  const { data } = await api.get(`groups-all?${qs.stringify(query)}`);
  return data;
}

export async function getGroup(id) {
  const { data } = await api.get(`groups/${id}`);
  return data;
}

export async function deleteGroup(id) {
  await api.delete(`groups/${id}`);
  return true;
}

export async function postGroup(params) {
  await api.post(`groups`, params);
  return true;
}

export async function putGroup(id, params) {
  await api.put(`groups/${id}`, params);
  return true;
}
