import React from 'react';
import { Typography, Form, Input, Col, Row, Divider, Button } from 'antd';
import MaskedInput from '../../../components/MaskedInput';

const { Title } = Typography;
const fundoCinza = 'rgba(0, 0, 0, 0.05)';
const fundoBranco = '#ffffff';

export default function Shareholder() {
  return (
    <>
      <Divider />
      <div style={{ marginTop: 20 }}>
        <Row>
          <Col span={24}>
            <Title level={4}>3 - Controle Acionário</Title>
          </Col>
        </Row>
        <Form.List name="shareholders">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div
                  key={field.key}
                  style={{
                    backgroundColor: index % 2 === 0 ? fundoCinza : fundoBranco,
                    margin: '10px 0',
                    padding: '5px 0',
                  }}
                >
                  <Row>
                    <Col sm={12} md={5}>
                      <Form.Item name={[field.name, 'shareholder']} fieldKey={[field.fieldKey, 'shareholder']}>
                        <Input placeholder="NOME DO SÓCIO" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={5}>
                      <Form.Item name={[field.name, 'role']} fieldKey={[field.fieldKey, 'role']} style={{ marginBottom: 0, width: '100%' }}>
                        <Input placeholder="CARGO" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={5}>
                      <Form.Item name={[field.name, 'shareholding']} fieldKey={[field.fieldKey, 'shareholding']}>
                        <Input placeholder="%" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={5}>
                      <Form.Item name={[field.name, 'cpf']} fieldKey={[field.fieldKey, 'cpf']}>
                        <MaskedInput mask="999.999.999-99" />
                      </Form.Item>
                    </Col>
                    <Col
                      sm={4}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button type="link" key={'removeShareholderButton_' + index} onClick={() => remove(index)} style={{ marginBottom: 0 }}>
                        Remover
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}

              <Row className="gx-mr-4 gx-mt-2" justify="end">
                <Col>
                  <Form.Item>
                    <Button onClick={() => add()}>Adicionar sócio</Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
}
