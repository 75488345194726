import api from './ApiService';
import qs from 'query-string';

export async function getTickets(query) {
  const { data } = await api.get(`tickets?${qs.stringify(query)}`);
  return data;
}

export async function getTicket(id) {
  const { data } = await api.get(`tickets/${id}`);
  return data;
}

export async function postTicket(params) {
  const { data } = await api.post(`tickets`, params);
  return data;
}

export async function getTicketMessages(id) {
  const { data } = await api.get(`tickets-message/${id}`);
  return data;
}

export async function putTicketMessages(id, params) {
  await api.put(`tickets-message/${id}`, params);
  return true;
}

export async function putTicketAccept(id) {
  await api.put(`tickets-accept/${id}`);
  return true;
}

export async function putTicketClose(id) {
  await api.put(`tickets-close/${id}`);
  return true;
}

export async function postTicketFile(id) {
  await api.post(`upload-ticket-file/${id}`);
  return true;
}
