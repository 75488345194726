// Customizer const
export const THEME_TYPE = 'THEME_TYPE';
export const THEME_TYPE_AFTER_LOGIN = 'THEME_TYPE_AFTER_LOGIN';
export const THEME_TYPE_LITE = 'THEME_TYPE_LITE';
export const THEME_TYPE_DARK = 'THEME_TYPE_DARK';
export const THEME_TYPE_SEMI_DARK = 'THEME_TYPE_SEMI_DARK';

export const HORIZONTAL_NAVIGATION = 'HORIZONTAL_NAVIGATION';

export const HORIZONTAL_MENU_POSITION = 'HORIZONTAL_MENU_POSITION';
export const ABOVE_THE_HEADER = 'ABOVE_THE_HEADER';
export const INSIDE_THE_HEADER = 'INSIDE_THE_HEADER';
export const BELOW_THE_HEADER = 'BELOW_THE_HEADER';

export const VERTICAL_NAVIGATION = 'VERTICAL_NAVIGATION';
export const NAV_STYLE_MINI = 'NAV_STYLE_MINI';

export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const LAYOUT_TYPE_AFTER_LOGIN = 'LAYOUT_TYPE_AFTER_LOGIN';

export const LAYOUT_TYPE_FRAMED = 'LAYOUT_TYPE_FRAMED';
export const LAYOUT_TYPE_BOXED = 'LAYOUT_TYPE_BOXED';
export const LAYOUT_TYPE_FULL = 'LAYOUT_TYPE_FULL';

export const NAV_STYLE = 'NAV_STYLE';
export const NAV_STYLE_AFTER_LOGIN = 'NAV_STYLE_AFTER_LOGIN';

export const NAV_STYLE_FIXED = 'NAV_STYLE_FIXED';
export const NAV_STYLE_MINI_SIDEBAR = 'NAV_STYLE_MINI_SIDEBAR';
export const NAV_STYLE_DRAWER = 'NAV_STYLE_DRAWER';

export const TAB_SIZE = 992;
