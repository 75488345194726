import { OPEN_GED_PERMISSIONS_MODAL, CLOSE_GED_PERMISSIONS_MODAL } from '../../constants/ActionTypes';

const INIT_STATE = {
  modalIsOpen: false,
  folderId: '',
};

export default function GedPermissions(state = INIT_STATE, action) {
  switch (action.type) {
    case OPEN_GED_PERMISSIONS_MODAL:
      return { ...state, modalIsOpen: true, folderId: action.payload };
    case CLOSE_GED_PERMISSIONS_MODAL:
      return INIT_STATE;
    default:
      return state;
  }
}
