import { TOGGLE_UPLOAD_VERSION_MODAL } from '../../constants/ActionTypes';

const INIT_STATE = {
  modalIsOpen: false,
  file: '',
};

export default function UploadVersionModal(state = INIT_STATE, action) {
  switch (action.type) {
    case TOGGLE_UPLOAD_VERSION_MODAL:
      return { ...state, modalIsOpen: !!action.payload, file: action.payload };
    default:
      return state;
  }
}
