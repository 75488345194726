import {
  GET_FILE,
  GET_FILE_SUCCESS,
  GET_FILE_ERROR,
  OPEN_FILE_PREVIEW_DRAWER,
  CLOSE_FILE_PREVIEW_DRAWER,
  OPEN_FOLDER_MODAL,
  CLOSE_FOLDER_MODAL,
  CREATE_FOLDER,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_ERROR,
  UPDATE_FOLDER,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_ERROR,
  OPEN_FILE_MODAL,
  CLOSE_FILE_MODAL,
  OPEN_FILE_PREVIEW_MODAL,
  CLOSE_FILE_PREVIEW_MODAL,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_ERROR,
  GET_ROOT,
  GET_ROOT_SUCCESS,
  GET_ROOT_ERROR,
  GET_FOLDER,
  GET_FOLDER_SUCCESS,
  GET_FOLDER_ERROR,
  SET_UPLOADING,
  DELETE_FOLDER,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_ERROR,
  DELETE_FILE,
  DELETE_FILES,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loadingRoot: false,
  loadingFolder: false,
  loadingFile: false,
  deletingFolder: false,
  deletingFile: false,
  deletingFiles: false,
  uploading: false,
  root: {},
  folderOpened: {},
  fileOpened: {},
  filePreviewDrawerOpened: false,
  folderModal: {},
  folderModalOpened: false,
  submitingFolder: false, //POST ou PUT
  fileModal: {},
  fileModalOpened: false,
  submitingFile: false, //PUT
  filePreviewModal: {},
  filePreviewModalOpened: false,
};

export default function Ged(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_ROOT:
      return { ...state, loadingRoot: true };
    case GET_ROOT_SUCCESS:
      return { ...state, loadingRoot: false, root: action.payload, folderOpened: action.payload };
    case GET_ROOT_ERROR:
      return { ...state, loadingRoot: false };
    case GET_FOLDER:
      return { ...state, loadingFolder: true };
    case GET_FOLDER_SUCCESS:
      return { ...state, loadingFolder: false, folderOpened: action.payload };
    case GET_FOLDER_ERROR:
      return { ...state, loadingFolder: false };
    case GET_FILE:
      return { ...state, loadingFile: true };
    case GET_FILE_SUCCESS:
      return { ...state, fileOpened: action.payload, loadingFile: false };
    case GET_FILE_ERROR:
      return { ...state, loadingFile: false };
    case OPEN_FILE_PREVIEW_DRAWER:
      return { ...state, filePreviewDrawerOpened: true };
    case CLOSE_FILE_PREVIEW_DRAWER:
      return { ...state, filePreviewDrawerOpened: false };
    case OPEN_FOLDER_MODAL:
      return { ...state, folderModalOpened: true, folderModal: action.payload };
    case CLOSE_FOLDER_MODAL:
      return { ...state, folderModalOpened: false, folderModal: {} };
    case CREATE_FOLDER:
    case UPDATE_FOLDER:
      return { ...state, submitingFolder: true };
    case CREATE_FOLDER_SUCCESS:
    case CREATE_FOLDER_ERROR:
    case UPDATE_FOLDER_SUCCESS:
    case UPDATE_FOLDER_ERROR:
      return { ...state, submitingFolder: false };
    case UPDATE_FILE:
      return { ...state, submitingFile: true };
    case UPDATE_FILE_SUCCESS:
    case UPDATE_FILE_ERROR:
      return { ...state, submitingFile: false };
    case OPEN_FILE_MODAL:
      return { ...state, fileModalOpened: true, fileModal: action.payload };
    case CLOSE_FILE_MODAL:
      return { ...state, fileModalOpened: false, fileModal: {} };
    case SET_UPLOADING:
      return { ...state, uploading: action.payload };
    case DELETE_FOLDER:
      return { ...state, deletingFolder: true };
    case DELETE_FOLDER_SUCCESS:
    case DELETE_FOLDER_ERROR:
      return { ...state, deletingFolder: false };
    case DELETE_FILE:
      return { ...state, deletingFile: true };
    case DELETE_FILES:
      return { ...state, deletingFiles: true };
    case DELETE_FILE_SUCCESS:
    case DELETE_FILE_ERROR:
      return { ...state, deletingFile: false };
    case OPEN_FILE_PREVIEW_MODAL:
      return { ...state, filePreviewModal: action.payload, filePreviewModalOpened: true };
    case CLOSE_FILE_PREVIEW_MODAL:
      return { ...state, filePreviewModal: {}, filePreviewModalOpened: false };
    default:
      return state;
  }
}
