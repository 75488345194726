import React, { useState } from 'react';
import { Modal, Form, Input, Button, Upload, message, Row, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUploadVersionModal, getFile } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';
import { authorizedMimeTypesUpload } from '../../constants';

const { Dragger } = Upload;

export function UploadVersionModal() {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [uploading, setUploading] = useState(false);
  const { modalIsOpen, file } = useSelector(({ uploadVersionModal }) => uploadVersionModal);
  const { token } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const { key = '', _id } = file;

  const closeModal = () => {
    dispatch(toggleUploadVersionModal(''));
    form.resetFields();
  };

  const props = {
    action: `${process.env.REACT_APP_API_URL}/upload-new-version-file?key=${key}`,
    headers: { authorization: `Bearer ${token}` },
    name: 'file',
    accept: authorizedMimeTypesUpload,
    multiple: false,
    showUploadList: false,
    beforeUpload: () => setUploading(true),
    data: { description },
    onChange(info) {
      try {
        const { status } = info.file;
        if (status === 'done') {
          message.success(`Arquivo '${info.file.name}' enviado com sucesso!`);
          setUploading(false);
          closeModal();
          dispatch(getFile(_id)); //Atualiza arquivo aberto
        } else if (status === 'error') {
          setUploading(false);
          message.error(`Não foi possível enviar o arquivo '${info.file.name}' no monento. Tente novamente.`);
        }
      } catch (error) {
        console.log(`LOG: onChangeUpload -> error`, error.message);
      }
    },
  };

  return (
    <Modal
      title="Enviar nova versão"
      visible={modalIsOpen}
      onCancel={closeModal}
      footer={
        <Button disabled={uploading} loading={uploading} type="secondary" onClick={closeModal}>
          {uploading ? 'Enviando' : <IntlMessages id="app.general.close_btn" />}
        </Button>
      }
    >
      <Spin spinning={uploading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            description: '',
          }}
        >
          <Form.Item
            label="Descrição"
            rules={[
              {
                required: true,
                message: 'A descrição é obrigatória',
              },
            ]}
          >
            <Input disabled={uploading} placeholder="Insira uma descrição" value={description} onChange={(e) => setDescription(e.target.value)} autoFocus />
          </Form.Item>
          {description.length > 0 && (
            <Row style={{ marginTop: 30 }}>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Clique ou arraste um arquivo nesta área para efetuar o upload</p>
                <p className="ant-upload-hint">Uma nova versão do arquivo será anexada</p>
              </Dragger>
            </Row>
          )}
        </Form>
      </Spin>
    </Modal>
  );
}
