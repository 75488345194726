import api from './ApiService';

export async function getSearch(query) {
  const { data } = await api.get(`search?q=${query}`);
  return data;
}
export async function getSearchFolders(query) {
  const { data } = await api.get(`search-folders?q=${query}`);
  return data;
}
