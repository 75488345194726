import antdpt_BR from 'antd/lib/locale-provider/pt_BR';
import pt_BRMessages from '../locales/pt_BR.json';

const pt_BRLang = {
  messages: {
    ...pt_BRMessages,
  },
  antd: antdpt_BR,
  locale: 'pt-BR',
};
export default pt_BRLang;
