import React from 'react';
import { Tag, Layout, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCollapsedSideNav } from '../../redux/actions';
import UserInfo from '../../components/UserInfo';
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from '../../constants/ThemeSetting';
import GlobalSearch from '../../components/GlobalSearch';
import { wLogoImg } from '../../assets/images';

const { REACT_APP_ENV_COLOR = 'green', REACT_APP_VERSION = 'v1.0.0', REACT_APP_ENV_TITLE = 'Ambiente de desenvolvimento' } = process.env;

const { Header } = Layout;

export default function Topbar() {
  const { width, navCollapsed, navStyle } = useSelector(({ settings }) => settings);
  const dispatch = useDispatch();

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      {width > TAB_SIZE && (
        <>
          <Link className="gx-d-block gx-d-lg-none gx-pointer" to="/">
            <img alt="" src={wLogoImg} />
          </Link>
          <GlobalSearch />
          <Button className="gx-mt-3" type="primary">
            <Link to="/tickets/create">Abrir novo chamado</Link>
          </Button>
        </>
      )}

      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-user-nav">
          <Tag color={REACT_APP_ENV_COLOR}>{`${REACT_APP_ENV_TITLE} (${REACT_APP_VERSION})`}</Tag>
        </li>
        <li className="gx-user-nav">
          <UserInfo />
        </li>
      </ul>
    </Header>
  );
}
