import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, Space, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeFolderModal, updateFolder, createFolder } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';
import MaskedInput from '../../components/MaskedInput';

const { TextArea } = Input;
const { Option } = Select;

export function FolderModal() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { folderModal, folderModalOpened, submitingFolder, folderOpened } = useSelector(({ ged }) => ged);
  const { allTemplates, loadingAllTemplates } = useSelector(({ templates }) => templates);

  const closeModal = () => {
    dispatch(closeFolderModal());
  };

  const onFinish = (data) => {
    if (!!folderModal) {
      dispatch(updateFolder(folderModal._id, data));
    } else {
      let params = {
        ...data,
        parent: folderOpened._id,
        template: data.template ? data.template : undefined,
      };
      dispatch(createFolder(params));
    }
  };

  useEffect(() => {
    if (!!folderModal) {
      form.setFieldsValue(folderModal);
    } else {
      form.resetFields();
    }
  }, [folderModal, form]);

  return (
    <Modal
      title={!!folderModal ? `Editando: ${folderModal.title}` : 'Nova pasta'}
      visible={folderModalOpened}
      onCancel={closeModal}
      width={500}
      forceRender
      footer={
        <Space>
          <Button disabled={submitingFolder} loading={submitingFolder} type="secondary" onClick={() => closeModal()}>
            <IntlMessages id="app.general.close_btn" />
          </Button>
          <Button disabled={submitingFolder} loading={submitingFolder} type="primary" onClick={() => form.submit()}>
            {!!folderModal ? 'Atualizar pasta' : 'Criar nova pasta'}
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          title: '',
          template: null,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Nome da pasta"
          name="title"
          rules={[
            {
              required: true,
              message: 'O nome da pasta é obrigatório.',
            },
          ]}
        >
          <Input disabled={submitingFolder} placeholder="Digite o nome da pasta..." autoFocus />
        </Form.Item>
        <Form.Item label="CNPJ" name="document">
          <MaskedInput mask="99.999.999/9999-99" placeholder="__.___.___/____-__" />
        </Form.Item>
        {!folderModal && (
          <Form.Item name="template" label="Criar estrutura a partir de um template (opcional)">
            <Select
              filterOption={(input, option) => !!option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              showSearch
              placeholder="Selecione..."
              disabled={loadingAllTemplates}
            >
              <Option value={null}>Nenhum</Option>
              {allTemplates.map((option) => (
                <Option key={option._id} value={option._id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Descrição" name="description">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
