import { POST_CUSTOMER, POST_CUSTOMER_ERROR, POST_CUSTOMER_SUCCESS } from '../../constants/ActionTypes';

const INIT_STATE = {
  postingCustomer: false,
};

export default function Customer(state = INIT_STATE, action) {
  switch (action.type) {
    case POST_CUSTOMER:
      return { ...state, postingCustomer: true };
    case POST_CUSTOMER_SUCCESS:
      return { ...state, postingCustomer: false };
    case POST_CUSTOMER_ERROR:
      return { ...state, postingCustomer: false };
    default:
      return state;
  }
}
