import React from 'react';
import { Row, Col } from 'antd';
import { logoEsquerdaImg, logoCentroImg, logoDireitaImg } from '../../../assets/images';

export default function Header() {
  return (
    <div className="gx-ml-4 gx-mr-4">
      <Row align="middle" justify="center">
        <Col sm={4}>
          <img style={{ maxHeight: 132 }} src={logoEsquerdaImg} alt="logo" />
        </Col>
        <Col align="center" sm={16}>
          <img src={logoCentroImg} alt="logo" />
        </Col>
        <Col sm={4}>
          <img style={{ maxHeight: 132 }} src={logoDireitaImg} alt="logo" />
        </Col>
      </Row>
      <Row justify="center" className="gx-mt-3">
        <h1>Ficha Cadastral - RUFF CJ Distribuidora de Petróleo Ltda.</h1>
      </Row>
    </div>
  );
}
