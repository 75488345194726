import enLang from './entries/en-US';
import esLang from './entries/es_ES';
import pt_BRLang from './entries/pt_BR';

const AppLocale = {
  en: enLang,
  es: esLang,
  'pt-BR': pt_BRLang,
};

const validateLocales = () => {
  // Valida se todas as chaves PTBR estão presentes tbm nos demais locales
  Object.keys(pt_BRLang.messages).forEach((key) => {
    [enLang, esLang].forEach((locale) => {
      if (!locale.messages[key]) throw new Error(`Chave '${key}' não encontrada para tradução ${locale.locale}. Favor adicionar chave no respectivo arquivo JSON.`);
    });
  });
};
validateLocales();

export default AppLocale;
