import { OPEN_COPY_MOVE_MODAL, CLOSE_COPY_MOVE_MODAL } from '../../constants/ActionTypes';

const INIT_STATE = {
  copyMoveModalIsOpen: false,
  mode: '', // COPY || MOVE
  objects: [],
};

export default function CopyMoveModal(state = INIT_STATE, action) {
  switch (action.type) {
    case OPEN_COPY_MOVE_MODAL:
      return {
        ...state,
        copyMoveModalIsOpen: true,
        mode: action.payload.mode,
        objects: action.payload.objects,
      };
    case CLOSE_COPY_MOVE_MODAL:
      return INIT_STATE;
    default:
      return state;
  }
}
