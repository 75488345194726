import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Spin, Modal, Row, Button, Space, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeFilePreviewModal } from '../../redux/actions';
import { getDownloadUrlFile } from '../../services';
import { isImage, isPdf } from '../../util';
import IntlMessages from '../../util/IntlMessages';

export function FilePreviewModal() {
  const { filePreviewModal, filePreviewModalOpened } = useSelector(({ ged }) => ged);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [mimetype, setMimetype] = useState(null);
  const [tempDownloadUrl, setTempDownloadUrl] = useState(null);

  const closeModal = useCallback(() => {
    dispatch(closeFilePreviewModal());
    setTempDownloadUrl(null);
    setLoading(true);
  }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data } = await getDownloadUrlFile(filePreviewModal._id);
        setTempDownloadUrl(data.tempDownloadUrl);
        setMimetype(data.mimetype);
        if (!isImage(data.mimetype) && !isPdf(data.mimetype)) {
          message.info(`Tipo de arquivo não permite visualização. Efetuando download...`);
          setTimeout(closeModal, 2000);
        }
      } catch (err) {
        closeModal();
      }
    };
    if (filePreviewModalOpened && filePreviewModal && !!filePreviewModal._id) {
      getData();
    }
  }, [filePreviewModal, filePreviewModalOpened, closeModal]);

  const img = isImage(mimetype);
  const minWidth = 300;
  const minHeight = 200;
  const maxWidth = img ? window.innerWidth * 0.4 : window.innerWidth;

  return (
    <Modal
      extra={<span>Preview</span>}
      width={maxWidth}
      title="Arquivo"
      visible={filePreviewModalOpened}
      onCancel={closeModal}
      centered
      footer={
        <Space>
          <Button type="secondary" onClick={() => closeModal()}>
            <IntlMessages id="app.general.close_btn" />
          </Button>
          <Button type="primary">
            <Link onClick={() => closeModal()} to={`/file/${filePreviewModal._id}`}>
              Mais detalhes
            </Link>
          </Button>
        </Space>
      }
    >
      <Row style={{ minWidth, minHeight, maxWidth }} align="middle" justify="center">
        <Spin size="large" spinning={loading}>
          {img ? (
            <img onLoad={() => setLoading(false)} alt="Carregando arquivo..." src={tempDownloadUrl} />
          ) : (
            <iframe allowFullScreen style={{ width: maxWidth * 0.95, height: '75vh' }} onLoad={() => setLoading(false)} title="Carregando arquivo..." src={tempDownloadUrl} frameBorder="0" />
          )}
        </Spin>
      </Row>
    </Modal>
  );
}
