import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import settingSagas from './Setting';
import profileSagas from './Profile';
import citySagas from './City';
import gedSagas from './Ged';
import commentsSagas from './Comments';
import timelineSagas from './Timeline';
import templatesSagas from './Templates';
import fileRevisionSagas from './FileRevision';

import customerSagas from './Customer';

export default function* rootSaga() {
  yield all([authSagas(), settingSagas(), profileSagas(), citySagas(), gedSagas(), commentsSagas(), timelineSagas(), templatesSagas(), fileRevisionSagas(), customerSagas()]);
}
