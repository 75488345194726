import React from 'react';
import { Typography, Form, Input, Col, Row, Divider, Checkbox } from 'antd';
import MaskedInput from '../../../components/MaskedInput';

const { TextArea } = Input;
const { Title, Text } = Typography;

const labelText = (label) => <Text style={{ padding: '0 16px' }}>{label}</Text>;

export default function Footer() {
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginLeft: 0,
  };

  return (
    <>
      <Divider />
      <div style={{ marginTop: 20, marginLeft: 30 }}>
        <Form.Item style={{ display: 'block' }} name="attachedRegistered" colon={false} label="Documentos Anexados ao Cadastro">
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              <Col sm={24} md={12}>
                <Checkbox style={radioStyle} value={1}>
                  Contrato Social/Ata
                </Checkbox>
                <Checkbox style={radioStyle} value={2}>
                  Última Alteração Contratual
                </Checkbox>
                <Checkbox style={radioStyle} value={3}>
                  Cartão CNPJ
                </Checkbox>
                <Checkbox style={radioStyle} value={4}>
                  Inscrição Estadual
                </Checkbox>
              </Col>
              <Col sm={24} md={12}>
                <Checkbox style={radioStyle} value={5}>
                  Últimos Balanços
                </Checkbox>
                <Checkbox style={radioStyle} value={6}>
                  CPF e RG dos Sócios
                </Checkbox>
                <Checkbox style={radioStyle} value={7}>
                  Procurações
                </Checkbox>
                <Checkbox style={radioStyle} value={8}>
                  Outros (especificar)
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <div style={{ marginTop: 30, textAlign: 'center', textDecoration: 'underline' }}>
          Ciente da responsabilidade criminal prevista no artigo 19 da Lei 7492 de 16/06/1986, declaro que as informações prestadas são a expressão da verdade.
        </div>
        <Row className="gx-mr-4 gx-mt-2">
          <Col xs={8}>
            <Form.Item style={{ display: 'block' }} name="signatureDate" colon={false} label={labelText('Dt. Assinatura Cliente')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <MaskedInput mask="99/99/9999" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xs={24} md={8}>
            <Form.Item name="marketAdvisorCredit" label={labelText('Assessor de Mercado')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input placeholder="Assessor de Mercado" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="gn" label={labelText('GN')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input placeholder="GN" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="initalCreditLimit" label={labelText('Lim. Créd. Inicial')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input placeholder="Lim. Créd. Inicial" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={8}>
            <Form.Item name="deadline" label={labelText('Prazo')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input placeholder="Prazo" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="deadlineDate" label={labelText('Data')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <MaskedInput mask="99/99/9999" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="creditCharge" label={labelText('Créd. Cobrança')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input placeholder="Créd. Cobrança" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Title level={3}>Parecer Comercial</Title>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="clientSituation" label={labelText('Informações/Situações do cliente no mercado')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="experiences" label={labelText('Experiência dos Operadores')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="othersBranchs" label={labelText('Opera outros postos? Bandeirados?')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="competitionSituation" label={labelText('Situação do posto frente à concorrência local')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="economicSituation"
              label={labelText('Situação financeira dos sócios é estável? Possuem outras atividades?')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              colon={false}
            >
              <TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="advisorOpinion" label={labelText('Parecer comercial do Assessor')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col sm={24} md={8}>
            <Form.Item name="creditProposal" label={labelText('Limite de Crédito proposto (R$)')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input placeholder="Limite de Crédito proposto" />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item name="creditProposalDeadline" label={labelText('Prazo')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input placeholder="Prazo" />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item name="advisorSignatureDate" label={labelText('Lim. Créd. Inicial')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <MaskedInput mask="99/99/9999" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
