import { call, put, select, takeLatest } from 'redux-saga/effects';
import { UPDATE_PROFILE } from '../../constants';
import { updateProfile as updateProfileService } from '../../services';
import { updateProfileSuccess, showLoading, hideLoading, showSuccess } from '../../redux/actions';

export const getProfileId = (state) => {
  return state.profile?._id;
};

function* updateProfileAsync({ payload }) {
  try {
    yield put(showLoading());
    const id = yield select(getProfileId);
    const { data } = yield call(updateProfileService, id, payload);
    yield put(updateProfileSuccess(data));
    yield put(showSuccess('app.profile.updated'));
  } catch {
  } finally {
    yield put(hideLoading());
  }
}

export default function* rootSaga() {
  yield takeLatest(UPDATE_PROFILE, updateProfileAsync);
}
