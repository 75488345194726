import React, { useEffect, useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Form, Input, Button, Row, Col, Divider, Popconfirm } from 'antd';
import MaskedInput from '../../components/MaskedInput';
import Header from '../../components/CustomerForm/Header/Header';
import Client from '../../components/CustomerForm/Client/Client';
import ClientInformation from '../../components/CustomerForm/ClientInformation/ClientInformation';
import Shareholder from '../../components/CustomerForm/Shareholder/Shareholder';
import Patrimony from '../../components/CustomerForm/Patrimony/Patrimony';
import References from '../../components/CustomerForm/References/References';
import Footer from '../../components/CustomerForm/Footer/Footer';
import PDFDocument from './PDFDocument';
import './customer-form.css';

const initialValues = { clients: [{}] };

export default function CustomerForm() {
  const [printData, setPrintData] = useState(initialValues);
  const [form] = Form.useForm();
  const [pdfReady, setPdfReady] = useState(false);
  const [preparingPdf, setPreparingPdf] = useState(false);

  useEffect(() => {
    try {
      const data = JSON.parse(localStorage.getItem('@customerForm'));
      form.setFieldsValue(data);
    } catch (error) {
      console.log(`LOG ~ useEffect ~ error`, error);
    }
  }, [form]);

  const onChangeForm = (current, allData) => {
    try {
      const serializedData = JSON.stringify(allData);
      localStorage.setItem('@customerForm', serializedData);
    } catch (error) {
      console.log(`LOG ~ onChangeForm ~ error`, error);
    }
  };

  const clearForm = () => {
    localStorage.removeItem('@customerForm');
    window.location.reload();
  };

  const downloadPDF = () => {
    setPreparingPdf(true);
    setPrintData(form.getFieldsValue());
    setTimeout(() => {
      setPreparingPdf(false);
      setPdfReady(true);
    }, 1500);
  };

  return (
    <>
      <Header />
      <Divider />
      <Form form={form} onValuesChange={onChangeForm} name="customerForm" initialValues={initialValues}>
        <Row>
          <Col xs={24} md={8}>
            <Form.Item label="Data" name="date" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <MaskedInput mask="99/99/9999" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Assessor de Mercado" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="marketAdvisor" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Nome" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Código assessor" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="code" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Código" />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="clients">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <Client field={field} index={index} />
                  {index > 0 ? (
                    <Row className="gx-mr-4 gx-mt-2" justify="end">
                      <Col>
                        <Form.Item>
                          <Button key={'removeClientButton_' + index} onClick={() => remove(index)}>
                            Remover Cliente
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              ))}

              <Divider dashed />
              <Row className="gx-mr-4" justify="end">
                <Col>
                  <Form.Item>
                    <Button type="primary" onClick={() => add()}>
                      Adicionar Cliente
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <ClientInformation />

        <Shareholder />

        <Patrimony />

        <References />

        <Footer />

        <Divider />

        <Row justify="center" className="gx-mt-3">
          <Col sm={24} md={12}>
            <Form.Item>
              <Popconfirm onConfirm={clearForm} title="Tem certeza?">
                <Button style={{ width: '100%', background: 'grey' }} type="dashed">
                  <b>Limpar formulário</b>
                </Button>
              </Popconfirm>
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item>
              {pdfReady ? (
                <PDFDownloadLink fileName="Ficha Cadastral.pdf" document={<PDFDocument values={printData} />}>
                  <Button onClick={() => setPdfReady(false)} style={{ width: '100%' }} type="danger">
                    <b>Clique para baixar</b>
                  </Button>
                </PDFDownloadLink>
              ) : (
                <Button loading={preparingPdf} onClick={() => downloadPDF()} style={{ width: '100%' }} type="primary">
                  {preparingPdf ? <b>Preparando documento para download...</b> : <b>Imprimir / Salvar em PDF</b>}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
