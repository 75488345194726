import React from 'react';
import { Typography, Form, Input, Col, Row, Divider, Button } from 'antd';

const { Title } = Typography;
const fundoCinza = 'rgba(0, 0, 0, 0.05)';
const fundoBranco = '#ffffff';

export default function References() {
  return (
    <>
      <Divider />
      <div style={{ marginTop: 20 }}>
        <Row>
          <Col span={24}>
            <Title level={4}>5 - Fontes de Referências-Bancárias e Comerciais</Title>
          </Col>
        </Row>

        <Form.List name="bankReferences">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <div
                      key={field.key}
                      style={{
                        backgroundColor: index % 2 === 0 ? fundoCinza : fundoBranco,
                        margin: '10px 0',
                        padding: '5px 0',
                      }}
                    >
                      <Row>
                        <Col sm={12} md={5}>
                          <Form.Item name={[field.name, 'bank']} fieldKey={[field.fieldKey, 'bank']}>
                            <Input placeholder="BANCO" />
                          </Form.Item>
                        </Col>
                        <Col sm={12} md={5}>
                          <Form.Item name={[field.name, 'agency']} fieldKey={[field.fieldKey, 'agency']}>
                            <Input placeholder="AGÊNCIA" />
                          </Form.Item>
                        </Col>
                        <Col sm={12} md={5}>
                          <Form.Item name={[field.name, 'contact']} fieldKey={[field.fieldKey, 'contact']}>
                            <Input placeholder="CONTATO" />
                          </Form.Item>
                        </Col>
                        <Col sm={12} md={5}>
                          <Form.Item name={[field.name, 'phone']} fieldKey={[field.fieldKey, 'phone']}>
                            <Input placeholder="TEL" />
                          </Form.Item>
                        </Col>
                        <Col sm={12} md={4}>
                          <Button type="link" key={'removeShareholderButton_' + index} onClick={() => remove(index)} style={{ marginBottom: 0 }}>
                            Remover
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  );
                })}

                <Row className="gx-mr-4 gx-mt-2" justify="end">
                  <Col>
                    <Form.Item>
                      <Button onClick={() => add()}>Adicionar ref. bancária</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.List>
      </div>

      <div style={{ marginTop: 20 }}>
        <Form.List name="commercials">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <div
                      key={field.key}
                      style={{
                        backgroundColor: index % 2 === 0 ? fundoCinza : fundoBranco,
                        margin: '10px 0',
                        padding: '5px 0',
                      }}
                    >
                      <Row>
                        <Col sm={12} md={5}>
                          <Form.Item name={[field.name, 'commercial']} fieldKey={[field.fieldKey, 'commercial']}>
                            <Input placeholder="REFERÊNCIA" />
                          </Form.Item>
                        </Col>
                        <Col sm={12} md={6}>
                          <Form.Item name={[field.name, 'frequency']} fieldKey={[field.fieldKey, 'frequency']}>
                            <Input placeholder="FREQ. COMPRA" />
                          </Form.Item>
                        </Col>
                        <Col sm={12} md={5}>
                          <Form.Item name={[field.name, 'contact']} fieldKey={[field.fieldKey, 'contact']}>
                            <Input placeholder="CONTATO" />
                          </Form.Item>
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Item name={[field.name, 'phone']} fieldKey={[field.fieldKey, 'phone']}>
                            <Input placeholder="TEL" />
                          </Form.Item>
                        </Col>
                        <Col sm={12} md={4}>
                          <Button type="link" key={'removeShareholderButton_' + index} onClick={() => remove(index)} style={{ marginBottom: 0 }}>
                            Remover
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  );
                })}

                <Row className="gx-mr-4 gx-mt-2" justify="end">
                  <Col>
                    <Form.Item>
                      <Button onClick={() => add()}>Adicionar ref. comercial</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.List>
      </div>
    </>
  );
}
