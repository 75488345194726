import { GET_ALL_TEMPLATES, GET_ALL_TEMPLATES_SUCCESS, GET_ALL_TEMPLATES_ERROR } from '../../constants/ActionTypes';

const INIT_STATE = {
  allTemplates: [],
  loadingAllTemplates: false,
};

export default function Templates(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_ALL_TEMPLATES:
      return { ...state, loadingAllTemplates: true };
    case GET_ALL_TEMPLATES_SUCCESS:
      return { ...state, loadingAllTemplates: false, allTemplates: action.payload };
    case GET_ALL_TEMPLATES_ERROR:
      return { ...state, loadingAllTemplates: false };
    default:
      return state;
  }
}
