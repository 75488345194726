import api from './ApiService';
import qs from 'query-string';

export async function getFileRevisions(query) {
  const { data } = await api.get(`file-revisions?${qs.stringify(query)}`);
  return data;
}

export async function getRevisors() {
  const { data } = await api.get('file-revisors');
  return data;
}

export async function getFileRevision(id) {
  const { data } = await api.get(`file-revisions/${id}`);
  return data;
}

export async function postFileRevision(params) {
  const { data } = await api.post(`file-revisions`, params);
  return data;
}

export async function putFileRevisionApproval(id, params) {
  await api.put(`file-revisions-approval/${id}`, params);
  return true;
}

export async function putFileRevisionRefuse(id, params) {
  await api.put(`file-revisions-refuse/${id}`, params);
  return true;
}

export async function putFileRevisionApprovalCommittee(id, params) {
  await api.put(`file-revisions-approval-committee/${id}`, params);
  return true;
}

export async function putFileRevisionRefuseCommittee(id, params) {
  await api.put(`file-revisions-refuse-committee/${id}`, params);
  return true;
}
