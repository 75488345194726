import { POST_CUSTOMER, POST_CUSTOMER_SUCCESS, POST_CUSTOMER_ERROR } from '../../constants/ActionTypes';

export const postCustomer = (params) => ({
  type: POST_CUSTOMER,
  payload: params,
});

export const postCustomerSuccess = (customer) => ({
  type: POST_CUSTOMER_SUCCESS,
  payload: customer,
});

export const postCustomerError = () => ({
  type: POST_CUSTOMER_ERROR,
});
