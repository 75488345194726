import { GET_ALL_TEMPLATES, GET_ALL_TEMPLATES_ERROR, GET_ALL_TEMPLATES_SUCCESS } from '../../constants/ActionTypes';

export const getAllTemplates = () => {
  return {
    type: GET_ALL_TEMPLATES,
  };
};

export const getAllTemplatesSuccess = (all) => {
  return {
    type: GET_ALL_TEMPLATES_SUCCESS,
    payload: all,
  };
};

export const getAllTemplatesError = () => {
  return {
    type: GET_ALL_TEMPLATES_ERROR,
  };
};
