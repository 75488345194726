import { SET_TREE_DATA, SET_TREE_LOADED, SET_TREE_EXPANDED, SET_TREE_SELECTED, SET_TREE_CHECKED } from '../../constants/ActionTypes';

export const setTreeData = (data = []) => {
  return {
    type: SET_TREE_DATA,
    payload: data,
  };
};

export const setTreeLoaded = (data = []) => {
  return {
    type: SET_TREE_LOADED,
    payload: data,
  };
};

export const setTreeExpanded = (data = []) => {
  return {
    type: SET_TREE_EXPANDED,
    payload: data,
  };
};

export const setTreeSelected = (data = []) => {
  return {
    type: SET_TREE_SELECTED,
    payload: data,
  };
};

export const setTreeChecked = (data = []) => {
  return {
    type: SET_TREE_CHECKED,
    payload: data,
  };
};
