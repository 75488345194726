import { useState, useCallback } from 'react';
import qs from 'query-string';

const setQueryStringWithoutPageReload = (qsValue) => {
  const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + qsValue;

  window.history.pushState({ path: newurl }, '', newurl);
};

const setQueryStringValue = (key, value, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

const getQueryStringValue = (key, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  return values[key];
};

export function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export function isImage(mimetype) {
  if (new RegExp(/image/gi).test(mimetype)) return true;
  return false;
}

export function isPdf(mimetype) {
  if (new RegExp(/pdf/gi).test(mimetype)) return true;
  return false;
}

// export function isDocOrXlsx(mimetype) {
//   if (
//     new RegExp(/application\/vnd.openxmlformats-officedocument/gi).test(mimetype) || // XLSX e DOCX
//     new RegExp(/application\/excel/gi).test(mimetype) || // XLS
//     new RegExp(/application\/msword/gi).test(mimetype) // DOC
//   ) {
//     return true;
//   }
//   return false;
// }
