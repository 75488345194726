import React from 'react';
import { Typography, Form, Input, Col, Row, Divider, Radio, Checkbox } from 'antd';

const { Title, Text } = Typography;

const labelText = (label) => <Text style={{ padding: '0 16px' }}>{label}</Text>;

export default function ClientInformation() {
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginLeft: 0,
  };

  return (
    <>
      <Divider />
      <div style={{ marginTop: 20 }}>
        <Row>
          <Col span={24}>
            <Title level={4}>2 - Informações do Cliente</Title>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <Form.Item style={{ display: 'block' }} name="clientType" colon={false} label="Atividade Econômica / Tipo de Cliente">
              <Radio.Group>
                <Radio style={radioStyle} value={1}>
                  PR
                </Radio>
                <Radio style={radioStyle} value={2}>
                  TRR
                </Radio>
                <Radio style={radioStyle} value={3}>
                  Grande Cons.
                </Radio>
                <Radio style={radioStyle} value={4}>
                  Cong.
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item style={{ display: 'block' }} name="realty" colon={false} label="Imóvel">
              <Radio.Group>
                <Radio style={radioStyle} value={1}>
                  Próprio
                </Radio>
                <Radio style={radioStyle} value={2}>
                  Alugado
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="rentDeadline" label="Prazo Aluguel" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item style={{ display: 'block' }} name="onus" colon={false} label="Ônus">
              <Radio.Group>
                <Radio style={radioStyle} value={1}>
                  Sim
                </Radio>
                <Radio style={radioStyle} value={2}>
                  Não
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item style={{ display: 'block' }} name="otherInfo" colon={false} label="Outras Inf. Relev.">
              <Checkbox.Group>
                <Checkbox style={radioStyle} value="Lava Rápido">
                  Lava Rápido
                </Checkbox>
                <Checkbox style={radioStyle} value="Lubrificação">
                  Lubrificação
                </Checkbox>
                <Checkbox style={radioStyle} value="Mini Mercado">
                  Mini Mercado
                </Checkbox>
                <Checkbox style={radioStyle} value="Restaurante">
                  Restaurante
                </Checkbox>
                <Checkbox style={radioStyle} value="Outros">
                  Outros
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={4}>
            <Form.Item name="flag" label={labelText('Bandeira')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Form.Item name="galonage" label={labelText('Galonagem')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Form.Item name="affiliates" label={labelText('Número de Filiais')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Form.Item name="employees" label={labelText('Núm. Funcionários')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Form.Item name="openingHours" label={labelText('Hor. Funcionamento')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Form.Item name="kms" label={labelText('Km Ida/Volta')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col xs={24} style={{ margin: 0 }}>
            <Title level={4}>Número de Bombas</Title>
            <Row>
              <Col sm={8}>
                <Form.Item name="leftGasoline" label={labelText('Gasolina')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item name="leftEthanol" label={labelText('Etanol')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item name="leftDiesel" label={labelText('Diesel')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={24} style={{ margin: 0 }}>
            <Title level={4}>Número de Bicos</Title>
            <Row>
              <Col sm={8}>
                <Form.Item name="rigthGasoline" label={labelText('Gasolina')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item name="rigthEthanol" label={labelText('Etanol')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item name="rigthDiesel" label={labelText('Diesel')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} colon={false}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
