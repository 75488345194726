import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeFileModal, updateFile } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';

const { TextArea } = Input;

export function FileModal() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { fileModal, fileModalOpened, submitingFile } = useSelector(({ ged }) => ged);

  const closeModal = () => {
    dispatch(closeFileModal());
  };

  const onFinish = (data) => {
    const params = { title: data.fileTitle, description: data.fileDescription };
    dispatch(updateFile(fileModal._id, params));
  };

  useEffect(() => {
    if (!!fileModal) {
      const data = { fileDescription: fileModal.description, fileTitle: fileModal.title };
      form.setFieldsValue(data);
    }

    return () => {
      form.resetFields();
    };
  }, [fileModal, form]);

  return (
    <Modal
      title={`Editando: ${fileModal.title}`}
      visible={fileModalOpened}
      onCancel={closeModal}
      width={500}
      forceRender
      footer={
        <Space>
          <Button disabled={submitingFile} loading={submitingFile} type="secondary" onClick={closeModal}>
            <IntlMessages id="app.general.close_btn" />
          </Button>
          <Button disabled={submitingFile} loading={submitingFile} type="primary" onClick={() => form.submit()}>
            Atualizar arquivo
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          fileTitle: '',
          fileDescription: '',
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Nome do arquivo"
          name="fileTitle"
          rules={[
            {
              required: true,
              message: 'O nome do arquivo é obrigatório.',
            },
          ]}
        >
          <Input disabled={submitingFile} placeholder="Digite o nome do arquivo..." autoFocus />
        </Form.Item>
        <Form.Item label="Descrição" name="fileDescription">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
