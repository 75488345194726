import {
  GET_ROOT,
  GET_ROOT_SUCCESS,
  GET_ROOT_ERROR,
  GET_FOLDER,
  GET_FOLDER_SUCCESS,
  GET_FOLDER_ERROR,
  GET_FILE,
  GET_FILE_SUCCESS,
  GET_FILE_ERROR,
  OPEN_FILE_PREVIEW_DRAWER,
  CLOSE_FILE_PREVIEW_DRAWER,
  OPEN_FOLDER_MODAL,
  CLOSE_FOLDER_MODAL,
  CREATE_FOLDER,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_ERROR,
  UPDATE_FOLDER,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_ERROR,
  REFRESH_GED,
  OPEN_FILE_MODAL,
  CLOSE_FILE_MODAL,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_ERROR,
  SET_UPLOADING,
  DELETE_FOLDER,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_ERROR,
  DELETE_FILE,
  DELETE_FILES,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
  OPEN_FILE_PREVIEW_MODAL,
  CLOSE_FILE_PREVIEW_MODAL,
} from '../../constants/ActionTypes';

import { message } from 'antd';
import { translateNotification } from '../../util/IntlMessages';

export const refreshGed = () => {
  return {
    type: REFRESH_GED,
  };
};

export const setUploading = (uploading) => {
  return {
    type: SET_UPLOADING,
    payload: uploading,
  };
};

export const getRoot = () => {
  return {
    type: GET_ROOT,
  };
};

export const getRootSuccess = (rootData) => {
  return {
    type: GET_ROOT_SUCCESS,
    payload: rootData,
  };
};

export const getRootError = () => {
  return {
    type: GET_ROOT_ERROR,
  };
};

export const getFolder = (folderId) => {
  return {
    type: GET_FOLDER,
    payload: folderId,
  };
};

export const getFolderSuccess = (data) => {
  return {
    type: GET_FOLDER_SUCCESS,
    payload: data,
  };
};

export const getFolderError = () => {
  return {
    type: GET_FOLDER_ERROR,
  };
};

export const getFile = (id) => {
  return {
    type: GET_FILE,
    payload: id,
  };
};

export const getFileSuccess = (file) => {
  return {
    type: GET_FILE_SUCCESS,
    payload: file,
  };
};

export const getFileError = () => {
  return {
    type: GET_FILE_ERROR,
  };
};

export const openFilePreviewDrawer = (id) => {
  return {
    type: OPEN_FILE_PREVIEW_DRAWER,
    payload: id,
  };
};

export const closeFilePreviewDrawer = () => {
  return {
    type: CLOSE_FILE_PREVIEW_DRAWER,
  };
};

export const openFolderModal = (folderModal = null) => {
  return {
    type: OPEN_FOLDER_MODAL,
    payload: folderModal,
  };
};

export const closeFolderModal = () => {
  return {
    type: CLOSE_FOLDER_MODAL,
  };
};

export const createFolder = (params) => {
  return {
    type: CREATE_FOLDER,
    payload: params,
  };
};

export const createFolderSuccess = () => {
  return {
    type: CREATE_FOLDER_SUCCESS,
  };
};

export const createFolderError = () => {
  return {
    type: CREATE_FOLDER_ERROR,
  };
};

export const updateFolder = (id, params) => {
  return {
    type: UPDATE_FOLDER,
    payload: { id, params },
  };
};

export const updateFolderSuccess = () => {
  return {
    type: UPDATE_FOLDER_SUCCESS,
  };
};

export const updateFolderError = () => {
  return {
    type: UPDATE_FOLDER_ERROR,
  };
};

export const openFileModal = (fileModal = null) => {
  return {
    type: OPEN_FILE_MODAL,
    payload: fileModal,
  };
};

export const closeFileModal = () => {
  return {
    type: CLOSE_FILE_MODAL,
  };
};

export const updateFile = (id, params) => {
  return {
    type: UPDATE_FILE,
    payload: { id, params },
  };
};

export const updateFileSuccess = () => {
  return {
    type: UPDATE_FILE_SUCCESS,
  };
};

export const updateFileError = () => {
  return {
    type: UPDATE_FILE_ERROR,
  };
};

export const deleteFolder = (folderId) => {
  return {
    type: DELETE_FOLDER,
    payload: folderId,
  };
};

export const deleteFolderSuccess = () => {
  return {
    type: DELETE_FOLDER_SUCCESS,
  };
};

export const deleteFolderError = () => {
  return {
    type: DELETE_FOLDER_ERROR,
  };
};

export const deleteFile = (fileId) => {
  return {
    type: DELETE_FILE,
    payload: fileId,
  };
};

export const deleteFiles = (files = []) => {
  const params = { filesId: files.map((x) => x._id) };
  return {
    type: DELETE_FILES,
    payload: params,
  };
};

export const deleteFileSuccess = () => {
  message.success(translateNotification('app.general.deleted'));
  return {
    type: DELETE_FILE_SUCCESS,
  };
};

export const deleteFileError = () => {
  return {
    type: DELETE_FILE_ERROR,
  };
};

export const openFilePreviewModal = (filePreviewModal = null) => {
  return {
    type: OPEN_FILE_PREVIEW_MODAL,
    payload: filePreviewModal,
  };
};

export const closeFilePreviewModal = () => {
  return {
    type: CLOSE_FILE_PREVIEW_MODAL,
  };
};
