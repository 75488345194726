export const footerText = `Copyright RUFF © ${new Date().getFullYear()}`;

export const defaultLocale = {
  languageId: 'portuguese',
  locale: 'pt-BR',
  name: 'Português',
  icon: 'br',
};

export const defaultHomePath = '/dashboard';

export const defaultPageSize = 50;
