import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_REVISORS, OPEN_NEW_FILE_REVISION_MODAL } from '../../constants';
import { getRevisors } from '../../services';
import { getRevisorsSuccess, getRevisorsError } from '../../redux/actions';

function* getRevisorsAsync() {
  try {
    const { data } = yield call(getRevisors);
    yield put(getRevisorsSuccess(data));
  } catch {
    yield put(getRevisorsError());
  } finally {
  }
}

export default function* rootSaga() {
  yield takeLatest([GET_REVISORS, OPEN_NEW_FILE_REVISION_MODAL], getRevisorsAsync);
}
